import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"
import { iDespesaFormaPagamentoDto } from "../dto/forma_pagamento"

const columnHelper = createColumnHelper<iDespesaFormaPagamentoDto>()

const defaultColumns = [
    columnHelper.accessor('codigo', {
        header: 'Cod.',
        size: 10,
    }),
    columnHelper.accessor('descricao', {
        header: 'Descrição',
        size: 300,
    }),
    columnHelper.accessor(row => `${row.reembolsavel === 1 ? `Sim` : `Não`}`, {
        header: 'Reembolsável',
        size: 30,
        id: "reembolsavel",
    }),
    columnHelper.accessor(row => `${row.cartao === 1 ? `Sim` : `Não`}`, {
        header: 'Cartão',
        size: 30,
    }),
    columnHelper.accessor(row => `${row.vencimento === 1 ? `Sim` : `Não`}`, {
        header: 'Vencimento',
        size: 30,
    }),
    columnHelper.accessor(row => `${row.ativo === 1 ? `Ativo` : `Desativado`}`, {
        header: 'Status',
        size: 30,
    }),
]

const defaultOrder:any = [
    {
        id: "reembolsavel",
        desc: true,
    },
]

function DespesasFormasPagamentoPage() {
    const [formasPagamento, setFormasPagamento] = useState<iDespesaFormaPagamentoDto[]>([])

    useEffect(() => {
        document.title = "Despesas Formas Pagamento | OB PORTUS"

        listarDados()
    }, [])

    async function listarDados() {
        const api = await reqGetNew(ApiEndpoints.despesasFormasPagamento)

        if (api.status === 200) {
            const clientesAux:iDespesaFormaPagamentoDto[] = api.data
            setFormasPagamento(clientesAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = formasPagamento[idx]
        const link = `/despesas/formaPagamento/editar?id=${reg.id_forma_pagamento}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = formasPagamento[idx]
        const link = `/despesas/formaPagamento/editar?id=${reg.id_forma_pagamento}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Despesas Formas de Pagamento</h1>
                <div className="div-row">
                    <Button onClick={() => {
                        window.location.assign(`/despesas/formaPagamento/cadastrar`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={formasPagamento} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default DespesasFormasPagamentoPage