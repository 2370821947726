import { useEffect, useState } from "react"
import NfSelecionarEmpresas from "./componentes/Empresas"
import NfsEmpresas from "./componentes/NfsEmpresas"
import { getUrlSearchParams } from "../urlParams"
import { ApiEndpoints, reqGetNew, reqPostNew } from "../../config/apiClient"
import NfView from "./componentes/NfView"
import { Spinner } from "react-bootstrap"

export default function NfInicialPage () {
    const [empresas, setEmpresas] = useState<any[]>([])
    const [empresa, setEmpresa] = useState<any>({})
    const [nota, setNota] = useState<any | null>(null)
    const [modelo, setModelo] = useState<any | null>(null)
    const [atualizarLista, setAtualizarLista] = useState<boolean>(true)
    const [loadingEmpresasSelect, setLoadingEmpresasSelect] = useState<boolean>(true)

    interface iHandleUrlUpdateDados {
        empresa: any,
        nota: any
    }

    useEffect(() => {

        document.title = 'NF Selecionar empresas | OB PORTUS'

        const getDados = async () => {
            setLoadingEmpresasSelect(true)
            const api = await reqGetNew(ApiEndpoints.nfEmpresasInfos)
            let empresasAux = []
            if (api.status === 200) {
                console.log(api.data)
                empresasAux = api.data
                setEmpresas(api.data)
            }
            handleUrlChange(empresasAux)
            setLoadingEmpresasSelect(false)
        }
        getDados()

    }, [])

    window.onpopstate = function(event:any) {
        // console.log("location: " + document.location + ", state: " + JSON.stringify(event.state))
        handleUrlChange(empresas)
    }
    async function handleUrlChange(empresas:any[]) {
        let urlParams:URLSearchParams = getUrlSearchParams()
        const id_empresa = urlParams.get("id_empresa")
        const id_nota = urlParams.get("id_nota")
        console.log(id_empresa, id_nota)
        if (id_empresa !== null && id_nota === null) {
            const empresaAux = empresas.filter(reg => reg.id_empresa === Number(id_empresa))
            if (empresaAux.length > 0) {
                setEmpresa(empresaAux[0])
                setNota(null)
                setModelo(null)
            }
        }

        if (id_empresa !== null && id_nota !== null) {
            const empresaAux = empresas.filter(reg => reg.id_empresa === Number(id_empresa))
            if (id_nota === "undefined") {
                setEmpresa(empresaAux[0])
                setNota(0)
            } else {
                const apiNota = await reqPostNew(ApiEndpoints.nfsNota, {id_nota: Number(id_nota)})
                if (apiNota.status === 200) {
                    setEmpresa(empresaAux[0])
                    setNota(apiNota.data)
                }
            }
        }

        if (id_empresa === null && id_nota === null) {
            setEmpresa({})
        }
    }
    function handleUrlUpdate(action:"empresa" | "nf" | "inicial", dados:iHandleUrlUpdateDados | null) {
        if (action === "nf") {
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nfs?id_empresa=${dados?.empresa.id_empresa}&id_nota=${dados?.nota.id_nota}`)
        }        
        if (action === "empresa") {
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nfs?id_empresa=${dados?.empresa.id_empresa}`)
        }
        if (action === "inicial") {
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nfs`)
        }
    }

    function handleEmpresa(empresa:any) {
        setEmpresa(empresa)
        handleUrlUpdate("empresa", {empresa: empresa, nota: null})
    }

    function handleCloseEmpresa() {
        setEmpresa({})
        handleUrlUpdate("inicial", null)
        setAtualizarLista(true)
    }

    function handleOpenNfView(nota:any) {
        setNota(nota)
        handleUrlUpdate("nf", {empresa: empresa, nota: nota})
        console.log(`carregar nota ${nota.id_nota}`)
        setAtualizarLista(false)
    }

    function handleOpenNfViewModelo(modelo: any) {
        setModelo(modelo)
        handleOpenNfView(0)
    }

    function handleCloseNfView() {
        setNota(null)
        setModelo(null)
        handleUrlUpdate("empresa", {empresa: empresa, nota: null})
        setAtualizarLista(false)
    }

    return (
        <div id="main">        
            {
                Object.values(empresa).length === 0 && (
                    <div>
                        <div className="div-row-space-between">
                            <h1 id="titleMain">NFs | Selecione a Empresa:</h1>
                        </div>
                        <br></br>
                        {
                            loadingEmpresasSelect &&
                            <Spinner></Spinner>
                        }
                        {
                            !loadingEmpresasSelect &&
                            <NfSelecionarEmpresas empresas={empresas} handleEmpresa={handleEmpresa} />
                        }
                    </div>
                )
            }
            {
                Object.values(empresa).length > 0 && (
                    <NfsEmpresas empresa={empresa} handleCloseEmpresa={handleCloseEmpresa} handleOpenNfView={handleOpenNfView} handleOpenNfViewModelo={handleOpenNfViewModelo} nota={nota} atualizar={atualizarLista} />
                )
            }
            {
                nota !== null &&
                <NfView empresa={empresa} notaDados={nota} modelo={modelo} handleClose={() => handleCloseNfView() } handleUpdateNfs={ () => { setAtualizarLista(true) } } />
            }
        </div>
    )
}