import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"
import { iDespesasCategoriaDto } from "../dto/despesas_categorias"

const columnHelper = createColumnHelper<iDespesasCategoriaDto>()

const defaultColumns = [
    columnHelper.accessor('id_categoria', {
        header: 'Cod. Categoria',
        size: 10,
    }),
    columnHelper.accessor('descricao', {
        header: 'Descrição',
        size: 300,
    }),
    columnHelper.accessor(row => `${row.ativo === 1 ? `Ativo` : `Desativado`}`, {
        header: 'Status',
        size: 30,
    }),
]

const defaultOrder:any = []

function DespesasCategoriasPage() {
    const [categorias, setCategorias] = useState<iDespesasCategoriaDto[]>([])

    useEffect(() => {
        document.title = "Despesas Categorias | OB PORTUS"

        listarDados()
    }, [])

    async function listarDados() {
        const api = await reqGetNew(ApiEndpoints.despesasCategorias)

        if (api.status === 200) {
            const aux:iDespesasCategoriaDto[] = api.data
            setCategorias(aux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = categorias[idx]
        const link = `/despesas/categoria/editar?id=${reg.id_categoria}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = categorias[idx]
        const link = `/despesas/categoria/editar?id=${reg.id_categoria}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Despesas Categorias</h1>
                <div className="div-row">
                    <Button onClick={() => {
                        window.location.assign(`/despesas/categoria/cadastrar`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={categorias} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default DespesasCategoriasPage