import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { ApiEndpoints, reqGetNew } from "../../config/apiClient"
import DataTable from "../../components/dataTable/DataTable"
import { iNfClientesDadosViewDto } from "./dto/nfClientes"
import NfModalImportarPlanilhaClientes from "./ClientesImportarPlanilhaModal"

const columnHelper = createColumnHelper<iNfClientesDadosViewDto>()

const defaultColumns = [
    columnHelper.accessor('id_cliente', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('nome', {
        header: 'Razão Social'
    }),
    columnHelper.accessor('nome_fantasia', {
        header: 'Nome Fantasia'
    }),
    columnHelper.accessor('empresa', {
        header: 'Empresa'
    }),
    columnHelper.accessor(row => `${row.tipo === 'PJ' ? `CNPJ ${row.cnpj}` : `CPF ${row.cpf}`}`, {
        header: 'Registro'
    }),
    columnHelper.accessor('tipo_cliente', {
        header: 'Tipo'
    }),
    columnHelper.accessor(row => `${row.logradouro}, ${row.numero}, ${row.bairro}, ${row.cidade} / ${row.cep} / ${row.uf}`, {
        header: 'Endereço',
    }),
]

const defaultOrder:any = []

function NfClientesPage() {
    const [clientes, setClientes] = useState<iNfClientesDadosViewDto[]>([])
    const [showModalImportar, setShowModalImportar] = useState<boolean>(false)

    useEffect(() => {
        document.title = "NF Clientes | OB PORTUS"

        const listarDados = async () => {
            const api = await reqGetNew(ApiEndpoints.nfClientes)

            if (api.status === 200) {
                const clientesAux:iNfClientesDadosViewDto[] = api.data
                setClientes(clientesAux)
            }
        }

        listarDados()
    }, [])

    async function listarClientes() {
        const api = await reqGetNew(ApiEndpoints.nfClientes)

        if (api.status === 200) {
            const clientesAux:iNfClientesDadosViewDto[] = api.data
            setClientes(clientesAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = clientes[idx]
        const link = `/nf/clientes/consultar?id=${reg.id_cliente}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = clientes[idx]
        const link = `/nf/clientes/consultar?id=${reg.id_cliente}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Clientes</h1>
                <div className="div-row">
                    <Button onClick={() => { setShowModalImportar(true) }} style={{marginRight: "10px"}}>Importar</Button>
                    <Button onClick={() => {
                        window.location.assign(`/nf/clientes/consultar`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={clientes} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}

            <NfModalImportarPlanilhaClientes show={showModalImportar} handleClose={() => { setShowModalImportar(false) }} handleUpdate={() => { listarClientes() }} />
        </div>
    )
}

export default NfClientesPage