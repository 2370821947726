import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
// import $ from 'jquery'
// import 'jquery-mask-plugin'
import { iNfEmpresaServicosEditDto, iNfServicosEmpresasDto } from "../dto/nf_servicos"
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import Select from 'react-select'

interface iChoiceSelect {
    value: string,
    label: string
}

function NfServicosEmpresaEditarPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [servicosEmpresasDados, setServicosEmpresasDados] = useState<iNfServicosEmpresasDto>({
        empresa: {},
        servicos: [],
        todosServicos: []
    })

    const [servicosDisponiveis, setServicosDisponiveis] = useState<iChoiceSelect[]>([])
    const [todosCodServ, setTodosCodServ] = useState<any[]>([])

    useEffect(() => {
        // $('.cod_serv').mask('00.00', {reverse: true});

        document.title = "Editar NF Serviços Empresa | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                id_empresa: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.nfServicosEmpresaConsultar, dados)

            if (api.status === 200) {
                const servico:iNfServicosEmpresasDto = api.data
                setServicosEmpresasDados(servico)
                // defineValueInInput('cod_serv', servico.cod_serv)
                // disabledInput('cod_serv', true)
                // defineValueInInput('descricao', servico.descricao)
                // defineValueInInput('iss', servico.iss_incidencia_municipio_prestador)

                let servicosCodAux:any = []

                for (let reg of servico.servicos) {
                    servicosCodAux.push({
                        "value": reg.cod_serv.cod_serv,
                        "label": `${reg.cod_serv.cod_serv} - ${reg.cod_serv.descricao}`
                    })
                }
                setServicosDisponiveis(servicosCodAux)

                servicosCodAux = []
                for (let reg of servico.todosServicos) {
                    servicosCodAux.push({
                        "value": reg.cod_serv,
                        "label": `${reg.cod_serv} - ${reg.descricao}`
                    })
                }
                setTodosCodServ(servicosCodAux)
            }

            setLoading(false)
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar NF Serviços Empresa | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // const form:any = event.target
        let formValidado = true

        let dados:iNfEmpresaServicosEditDto = {
            id_empresa: servicosEmpresasDados.empresa.id_empresa,
            cod_serv: servicosDisponiveis.reduce((prev:any, current:any, idx:number, arr:any) => {
                let ids:string[] = prev as any
                ids.push(current.value)
                return ids
            }, []) as any,
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        cadastrar(dados)
    
    }

    async function cadastrar(dados:iNfEmpresaServicosEditDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.nfServicosEmpresaCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Serviços editados com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/servicos/empresas`)
        }
        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Serviços Empresas{Object.values(servicosEmpresasDados).length === 0 ? '' : ` - ${servicosEmpresasDados.empresa.nome}`}</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/nf/servicos/empresas`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formNfServicosEmpresa' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-12">
                        
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Selecione os serviços que ficaram disponíveis para:</Form.Label>
                            <Select name='cods_serv' id='cods_serv' value={servicosDisponiveis} options={todosCodServ} isMulti closeMenuOnSelect={false} placeholder="Serviços:" onChange={
                                (choice:any) => {
                                    setServicosDisponiveis(choice)
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6",
                                }),
                            }} />
                        </Form.Group>

                    </Col>
                </Row>

                {
                    Object.values(servicosEmpresasDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Salvar'}</Button>
                }
            </Form>
        </div>
    )
}

export default NfServicosEmpresaEditarPage