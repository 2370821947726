import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import $ from 'jquery'
import 'jquery-mask-plugin'
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput, disabledInput } from "../../../components/funcForInput"
import { iNfCidadeDto } from "../dto/nf_cidades"

function formatarCep(valor:string):string {
    defineValueInInput("cep_aux", valor)
    return $("#cep_aux").masked(valor)
}

function NfCidadeFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [cidadeDados, setCidadeDados] = useState<iNfCidadeDto | any>({})

    useEffect(() => {
        $('.valorIss').mask('00.00', {reverse: true});
        $('.valorCep').mask('00000-000', {reverse: true});

        document.title = "Cadastrar NF Cidade | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("cod")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                cod_cidade: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.nfCidadeConsultar, dados)

            if (api.status === 200) {
                const cidade:iNfCidadeDto = api.data
                console.log(cidade)
                setCidadeDados(cidade)
                defineValueInInput('cod_cidade', cidade.cod_cidade)
                disabledInput("cod_cidade", true)
                defineValueInInput('nome', cidade.nome)
                defineValueInInput('uf', cidade.uf)
                defineValueInInput('cep', formatarCep(cidade.cep))
                defineValueInInput('iss', cidade.iss)
            }

            setLoading(false)
        }

        let titleH1: any = document.getElementById("titleMain")

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar NF Cidade | OB PORTUS"
            titleH1.innerHTML = "Editar NF Cidade"
        } else {
            titleH1.innerHTML = "Cadastrar NF Cidade"
            disabledInput("cod_cidade", false)
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iNfCidadeDto = {
            cod_cidade: Object.values(cidadeDados).length > 0 ? cidadeDados?.cod_cidade : Number(form.cod_cidade.value),
            nome: form.nome.value,
            cep: form.cep.value,
            uf: form.uf.value,
            iss: form.iss.value
            // descricao: form.descricao.value,
            // iss_incidencia_municipio_prestador: form.iss.value,
        }
        
        if (dados.cod_cidade <= 0 ) {
            form.cod_cidade.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.nome === "") {
            form.nome.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.uf === "") {
            form.uf.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cep === "") {
            form.cep.nextElementSibling.style.display = "block"
            formValidado = false
        }

        dados.cep = dados.cep.replaceAll("-", '')

        if (dados.iss === "") {
            form.iss.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(cidadeDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(cidadeDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iNfCidadeDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.nfCidadeCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Cidade cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/cidades`)
        }
        setLoading(false)

    }

    async function editar(dados: iNfCidadeDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.nfCidadeEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cidade editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/cidades`)
        }

        setLoading(false)
    }

    async function handleExcluir() {
        if (!window.confirm('Tem certeza que deseja excluir a Cidade?')) {
            return
        }

        const dados = {
            cod_cidade: cidadeDados.cod_cidade
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.nfCidadeDeletar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cidade excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/cidades`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Cidade</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/nf/cidades`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formNfServico' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Código:</Form.Label>
                            <Form.Control name='cod_cidade' type="number" id='cod_cidade' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Código!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Valor ISS (Incidencia Municipio Prestador):</Form.Label>
                            <Form.Control name='iss' type="text" id='iss' className="valorIss" placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o ISS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control name='nome' type="text" id='nome' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>UF:</Form.Label>
                            <Form.Control name='uf' type="text" id='uf' maxLength={2} placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o UF!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control name='cep' type="text" id='cep' className="valorCep" placeholder="12345-000"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CEP!
                            </Form.Control.Feedback>
                            <Form.Control name='cep_aux' type="text" id='cep_aux' className="valorCep" placeholder="12345-000" style={{display: "none"}}/>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(cidadeDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(cidadeDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(cidadeDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluir()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default NfCidadeFormPage