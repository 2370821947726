import { useEffect, useState } from 'react'
import styles from '../styles/inicial.module.css'
import $ from 'jquery'
import 'jquery-mask-plugin'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { defineValueInInput, getValueFromInput } from '../../../components/funcForInput'
import dayjs from 'dayjs'
import { ApiEndpoints, reqPostNew } from '../../../config/apiClient'
import { iNfNotasViewDto } from '../dto/nf_notas'
import { createColumnHelper } from '@tanstack/react-table'
import DateTableSelectedNfs from './NfsLista'
import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import NfModalModelos from './NfModalModelos'
import NfModalImportarPlanilha from './NfImportarPlanilha'
import { toast } from 'react-toastify'
import funcSleep from '../../../components/funcSleep'
import LoadingModal from '../../../components/loadingModal'
import { getCookie } from '../../../config/cookies'

interface iProps {
    empresa: any
    handleCloseEmpresa: any
    handleOpenNfView: any
    handleOpenNfViewModelo: any
    nota: any | null
    atualizar: boolean
}

interface iNfNotasDownloadDto {
    id_nota: number
    ambiente: string
    codigo_verificacao: string
    tipo_arquivo: "pdf" | "xml"
}

const columnHelper = createColumnHelper<iNfNotasViewDto>()

const defaultColumns = [
    columnHelper.accessor(row => '', {
        id: 'select',
        header: ({table}) => (
            <input type="checkbox" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({row}) => (
            <input type="checkbox" checked={row.getIsSelected()} disabled={!row.getCanSelect()} onChange={row.getToggleSelectedHandler()} />
        ),
        size: 10,
        enableSorting: false,
    }),
    columnHelper.accessor(row => `${dayjs(row.data_servico).format("MM/YYYY")}`, {
        header: 'Compet.',
        size: 0,
    }),
    columnHelper.accessor(row => `#${row.id_nota} ${row.descricao}`, {
        header: 'Descrição',
        size: 240,
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        size: 40,
    }),
    columnHelper.accessor(row => row.cod_serv.iss_incidencia_municipio_prestador === 0 ? `Tributada ${row.ambiente?.ambiente === "ah" ? " - AH" : ""}` : `Retida ${row.ambiente?.ambiente === "ah" ? " - AH" : ""}`, {
        header: 'Situação',
        size: 40,
    }),
    columnHelper.accessor('numero_nf', {
        header: 'NF',
        size: 20,
    }),
    columnHelper.accessor(row => ``, {
        id: 'view',
        header: '',
        cell: ({row}) => (
            <div>
                { row.original.emitido === 1 &&
                    <a href={`${ApiEndpoints.apiNew}/nf/nota/view/pdf/${row.original.id_nota}`}>
                        <span className="material-symbols-outlined" style={{fontSize: '20px', zIndex: '10'}}>
                            visibility
                        </span>
                    </a>
                }
            </div>
        ),
        size: 8,
    }),
    columnHelper.accessor(row => `${dayjs(row.data_servico).format("DD/MM/YYYY")}`, {
        header: 'Data Serv.',
        size: 60,
    }),
    columnHelper.accessor(row => row.cod_serv.cod_serv, {
        header: 'Serv.',
        size: 20,
    }),
    columnHelper.accessor('valor_servicos', {
        header: 'R$ Vl.',
        size: 50,
    }),
    columnHelper.accessor(row => row.id_cliente.nome, {
        header: 'Tomador',
        size: 220
    }),
]

const defaultOrder:any[] = []

function formatarDinheiro(valor:string):string {
    defineValueInInput("dinheiro_aux", valor)
    return $("#dinheiro_aux").masked(valor)
}

function ModalMessageXMLError(props:any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b>Erro</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.show &&
                    <div>
                        <a href={`/nfs?id_empresa=${props.xml.nota.id_empresa.id_empresa}&id_nota=${props.xml.nota.id_nota}`} target="_blank" rel="noreferrer">Ir para nota</a>
                        <br></br>
                    </div>
                }
                <textarea id="modal_msg_xml_erro" className='form-control' style={{height: "512px"}} value={props.xml.error} readOnly></textarea>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function NfsEmpresas({ empresa, handleCloseEmpresa, handleOpenNfView, handleOpenNfViewModelo, nota, atualizar }:iProps) {
    useEffect(() => {
        $('.dinheiro').mask('###.###.###.###.###.###.##0,00', {reverse: true, placeholder: "0,00"});
        if (atualizar) {
            $('.competencia').mask('00/0000', { reverse: false })
            defineValueInInput("competencia", dayjs().format("MM/YYYY"))
            setCompetencia(dayjs().format("YYYY-MM"))
            document.title = `NF ${empresa.nome} | OB PORTUS`
            getNotas()
        }
        console.log(`status view ${atualizar}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [atualizar])

    const [notas, setNotas] = useState<any[]>([])
    const [competencia, setCompetencia] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingModal, setLoadingModal] = useState<boolean>(false)
    const [selection, setSelection] = useState<boolean>(false)
    const [showModalModelos, setShowModalModelos] = useState<boolean>(false)
    const [showModalImportar, setShowModalImportar] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const showMenuNovaNF = Boolean(anchorEl)
    const handleClickNovaNF = (event:any) => {
        setAnchorEl(event.currentTarget)
    }
    const [showModalXMLError, setShowModalXMLError] = useState<boolean>(false)
    const [XMLModalError, setXMLModalError] = useState<any>(false)

    async function getNotas():Promise<void> {
        setLoading(true)
        let competencia:string = getValueFromInput("competencia")
        if (competencia.length === 7) {
            competencia = `${competencia.split("/")[1]}-${competencia.split("/")[0]}`
        }
        const dadosNotas = {
            id_empresa: empresa.id_empresa,
            competencia: competencia
        }
        const api = await reqPostNew(ApiEndpoints.nfsEmpresa, dadosNotas)
        if (api.status === 200) {
            let notas_aux:any[] = api.data
            for (let idx in notas_aux) {
                notas_aux[idx].valor_servicos = `R$ ${formatarDinheiro(notas_aux[idx].valor_servicos)}`
            }
            setNotas(notas_aux)
            console.log(api.data)
            setCompetencia(competencia)
        }
        setLoading(false)
    }

    function handlePesquisaCompetencia(event:any) {
        let competenciaValue = String(event.target.value)
        if (competenciaValue.length === 7) {
            const arrayComp = competenciaValue.split("/")
            const competenciaAux = `${competenciaValue.split("/")[1]}-${competenciaValue.split("/")[0]}`
            const dados = {
                "id_empresa": empresa.id_empresa,
                "mes": Number(arrayComp[0]),
                "ano": Number(arrayComp[1])
            }
            if (dados.mes < 0 || dados.mes >= 13) {
                return
            }
            if (dados.ano <= 2023 || dados.ano >= Number(dayjs().add(1, 'year').add(1, 'year').format("YYYY"))) {
                return
            }
            if (competenciaAux === competencia) {
                return
            }

            console.log(dados)
            getNotas()
        }
        if (competenciaValue.length === 0) {
            const dados = {
                "id_empresa": empresa.id_empresa,
                "mes": null,
                "ano": null
            }
            if (competenciaValue === competencia) {
                return
            }
            console.log(dados)
            getNotas()
        }
    }

    function handleRowClick(event: any) {
        let idx = Number(event.target.parentElement.getAttribute('about'))
        if (event.target.localName === "span") {
            idx = Number(event.target.parentElement.parentElement.parentElement.parentElement.getAttribute('about'))
        }
        const nota = notas[idx]
        if (event.button === 1 && event.target.localName !== "span") {
            window.open(`/nfs?id_empresa=${empresa.id_empresa}&id_nota=${nota.id_nota}`)
        } else if (event.target.localName !== "span") {
            handleOpenNfView(nota)
        }

        if (event.target.localName === "span") {

            if (nota.emitido === 0) {
                toast.warning("Nota ainda não foi emitida!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }

            // if (event.button === 0 && nota.emitido === 1) {
            //     window.open(`${ApiEndpoints.apiNew}/nf/nota/view/pdf/${nota.id_nota}`)
            // }

            // if (event.button === 1 && nota.emitido === 1) {
            //     window.open(`${ApiEndpoints.apiNew}/nf/nota/view/pdf/${nota.id_nota}`)
            //     window.focus()
            // }
        }

        // console.log(event)
        // console.log(event.target.localName)
        // console.log(nota)
    }

    function handleModeloSelect(modelo: any) {
        setShowModalModelos(false)
        handleOpenNfViewModelo(modelo)
    }

    async function handleEmitirNotasSelect() {

        let notas:number[] = getNfsSelected()

        if (notas.length === 0) {
            toast.warning("Seleciona alguma NF!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        setLoadingModal(true)
        for (let id_nota of notas) {
            if (!window.confirm(`Tem certeza que deseja EMITIR NF #${id_nota}?`)) {
                return
            }
            const api = await reqPostNew(ApiEndpoints.nfsEmissao, {id_nota: id_nota})
            if (api.status === 200) {
                console.log(api.data)
                if (api.data.status === "ERROR") {
                    toast.error(`${api.data.msg} - ID_NOTA#${id_nota}`, {
                        containerId: 1,
                        position: 'bottom-center',
                        theme: 'dark'
                    })
                    setXMLModalError(api.data)
                    setShowModalXMLError(true)
                    break
                }
                if (api.data.status === "OK") {
                    toast.success(`Nota Fiscal EMITIDA! - ID_NOTA#${id_nota}`, {
                        containerId: 1,
                        position: 'bottom-center',
                        theme: 'dark'
                    })
                    let dadosDownload:iNfNotasDownloadDto = {
                        id_nota: api.data.nota.id_nota,
                        ambiente: api.data.nota.ambiente.ambiente,
                        codigo_verificacao: api.data.nota.codigo_verificacao,
                        tipo_arquivo: "pdf",
                    }
                    await download(dadosDownload, api.data.nota)
                }
            }
            await funcSleep(200)
        }
        setSelection(false)
        await getNotas()
        setLoadingModal(false)

    }

    async function download(dadosDownload:iNfNotasDownloadDto, nfDados:any) {

        return new Promise(function (resolve, reject) {
            var token = getCookie('token')
            let request = new XMLHttpRequest();
    
            request.responseType = "blob";
            request.open("POST", dadosDownload.tipo_arquivo === "pdf" ? ApiEndpoints.nfsDownloadPDF.endpoint : ApiEndpoints.nfsDownloadXML.endpoint, true)
            request.setRequestHeader("Content-Type", "application/json")
            request.setRequestHeader("Authorization", `Bearer ${token}`)
            request.send(JSON.stringify(dadosDownload))
    
            request.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    const imageURL = window.URL.createObjectURL(this.response)
                    const anchor = document.createElement("a")
                    anchor.href = imageURL
                    anchor.download = `NFe ${nfDados.numero_nf} - ${nfDados.id_cliente.nome}.${dadosDownload.tipo_arquivo === 'pdf' ? 'pdf' : 'xml'}`
                    document.body.appendChild(anchor)
                    anchor.click()
                    resolve('')
                } else if (this.status === 401 || this.status === 400) {
                    toast.error('Erro Download!', {
                        containerId: 1,
                        position: 'top-center',
                        theme: 'dark'
                    })
                    resolve('')
                }
            }
        })

    }

    async function handleDeletarNotasSelect() {
        let notas:number[] = getNfsSelected()

        if (notas.length === 0) {
            toast.warning("Seleciona alguma NF!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        if (!window.confirm("Tem certeza que deseja deletar as NF selecionadas?")) {
            return
        }

        setLoadingModal(true)
        for (let id_nota of notas) {
            const api = await reqPostNew(ApiEndpoints.nfsDeletar, {id_nota: id_nota})
            if (api.status === 200) {
                console.log(api)
                toast.success(`ID_NOTA#${id_nota} NF dados deletado com sucesso!`, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                await funcSleep(200)
            }
        }
        await getNotas()
        setLoadingModal(false)

    }

    async function handleDownloadNotasSelect() {

        let notas_ids:number[] = getNfsSelected()

        if (notas_ids.length === 0) {
            toast.warning("Seleciona alguma NF!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        if (!window.confirm("Tem certeza que deseja EMITIR as NF selecionadas?")) {
            return
        }

        setLoadingModal(true)
        for (let id_nota of notas_ids) {
            const nota = notas.filter(reg => reg.id_nota === id_nota)[0]
            let dadosDownload:iNfNotasDownloadDto = {
                id_nota: nota.id_nota,
                ambiente: nota.ambiente.ambiente,
                codigo_verificacao: nota.codigo_verificacao,
                tipo_arquivo: "pdf",
            }
            await download(dadosDownload, nota)
            await funcSleep(200)
        }
        setLoadingModal(false)

    }

    function getNfsSelected():any[] {
        let rows = getValueFromInput('rowsSelected')
        let notasAux:any []= JSON.parse(rows)
        notasAux = notasAux.map((reg:any, idx:any) => (notas[Number(reg[0])].id_nota))
        console.log(notasAux)
        return notasAux
    }

    async function downloadRelatorioExcel() {
        if (!window.confirm("Tem certeza que deseja baixar o relatório do filtro atual?")) {
            return
        }

        setLoadingModal(true)

        const dadosDownload = {
            id_empresa: empresa.id_empresa,
            competencia: competencia
        }

        console.log(dadosDownload)

        return new Promise(function (resolve, reject) {
            var token = getCookie('token')
            let request = new XMLHttpRequest();
    
            request.responseType = "blob";
            request.open("POST", ApiEndpoints.nfsDownloadRelatorioExcel.endpoint, true)
            request.setRequestHeader("Content-Type", "application/json")
            request.setRequestHeader("Authorization", `Bearer ${token}`)
            request.send(JSON.stringify(dadosDownload))
    
            request.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    const imageURL = window.URL.createObjectURL(this.response)
                    const anchor = document.createElement("a")
                    anchor.href = imageURL
                    anchor.download = `${empresa.nome} NFs Relatorio ${dadosDownload.competencia === "" ? "" : dadosDownload.competencia}.xlsx`
                    document.body.appendChild(anchor)
                    anchor.click()
                    setLoadingModal(false)
                    resolve('')
                } else if (this.status === 401 || this.status === 400) {
                    toast.error('Erro Download!', {
                        containerId: 1,
                        position: 'top-center',
                        theme: 'dark'
                    })
                    setLoadingModal(false)
                    resolve('')
                }
            }
        })
    }

    return (
        <div style={nota === null ? {zIndex: 10} : {zIndex: 10, opacity: 0, position: "fixed", top: "-100%"}}>
            <NfModalModelos show={showModalModelos} handleClose={() => { setShowModalModelos(false) }} empresa={empresa} handleModelo={handleModeloSelect} />
            <NfModalImportarPlanilha show={showModalImportar} handleClose={() => { setShowModalImportar(false) }} handleAtualizarListaNotas={() => { getNotas() }} empresa={empresa} />
            <ModalMessageXMLError
                show={showModalXMLError}
                onHide={() => setShowModalXMLError(false)}
                xml={XMLModalError}
            />
            <LoadingModal show={loadingModal} handleClose={(event:any) => { setLoadingModal(!loadingModal) }} />
            <Form.Control name='dinheiro_aux' type="text" id='dinheiro_aux' className='dinheiro' placeholder="0,00" style={{display: "none"}} />
            <div className="div-row-space-between">
                <div className={styles.divEmpresaItem} onClick={(e) => { handleCloseEmpresa() }}>
                    <img src={empresa.logo} alt="banner_empresa" width={80}></img>
                    <p>{empresa.nome}</p>
                </div>
            </div>
            <br></br>
            <div className='div-row'>
                <Form.Control onKeyUp={handlePesquisaCompetencia} type="text" id='competencia' className='competencia' placeholder="Competência" style={{width: "150px", marginRight: "10px" }}/>
                <Button variant='secundary' style={{marginRight: "10px"}} onClick={() => setSelection(!selection)}>Seleção: {selection ? "Ativado" : "Desativado"}</Button>
                <div
                    className='header-sub-group'
                    aria-controls={showMenuNovaNF ? 'nova-nf-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={showMenuNovaNF ? 'true' : undefined}
                    onClick={handleClickNovaNF}
                    style={{marginRight: "10px"}}
                >
                    <Button className='div-row-space-between'>
                        Nova NF <span className="material-symbols-outlined" style={{fontSize: "0.8em", marginLeft: "5px"}}>note_add</span>
                    </Button>
                </div>
                <Button onClick={() => { setShowModalImportar(true) }} style={{marginRight: "10px"}}>Importar</Button>
                { selection && <Button onClick={() => { handleDeletarNotasSelect() }} variant='danger' style={{marginRight: "10px"}}>Deletar</Button> }
                { selection && <Button onClick={() => { handleEmitirNotasSelect() }} variant='warning' style={{marginRight: "10px"}}>Emitir</Button> }
                { selection && <Button onClick={() => { handleDownloadNotasSelect() }} variant='success' style={{marginRight: "10px"}}>Download</Button> }
                <Button onClick={() => { downloadRelatorioExcel() }} variant='success' style={{marginRight: "10px"}}>Relatório Excel</Button>

                <Menu
                    id="nova-nf-menu"
                    anchorEl={anchorEl}
                    open={showMenuNovaNF}
                    onClose={() => {setAnchorEl(null)}}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => { setAnchorEl(null); handleOpenNfView(0); }} role='nova_nf' onAuxClick={(e) => {
                        if (e.button === 1) {
                            window.open(`/nfs?id_empresa=${empresa.id_empresa}&id_nota=undefined`)
                        }
                    }}>Nova</MenuItem>
                    <MenuItem onClick={() => { setAnchorEl(null); setShowModalModelos(true); }} role='modelos'>Modelos</MenuItem>
                </Menu>
            </div>
            <br></br>
            { loading && <Spinner></Spinner> }
            {!loading && <DateTableSelectedNfs dados={notas} tableSize={'auto'} defaultColumns={defaultColumns} defaultOrder={defaultOrder} select={selection} handleRowClick={handleRowClick} />}
        </div>
    )
}