import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqGetNew, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput } from "../../../components/funcForInput"
import { iDespesaCartaoFormDto, iDespesaCartaoViewDto } from "../dto/despesas_cartoes"
import Select from "react-select"

function DespesasCartoesFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [cartaoDados, setCartaoDados] = useState<iDespesaCartaoViewDto | any>({})

    const [empresa, setEmpresa] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [empresasFiltro, setEmpresasFiltro] = useState<any[]>([])

    const [colaborador, setColaborador] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [colsFiltro, setColsFiltro] = useState<any[]>([])

    useEffect(() => {
        document.title = "Cadastrar Cartão | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            await getApenasDados()

            const dados = {
                id_cartao: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.despesasCartaoConsultar, dados)

            if (api.status === 200) {
                const cartao:iDespesaCartaoViewDto = api.data
                setCartaoDados(cartao)
                defineValueInInput('num_cartao', cartao.num_cartao)
                defineValueInInput('nome_portador', cartao.nome_portador)
                defineValueInInput('status', cartao.ativo)

                setEmpresa({ "value": String(cartao.id_empresa.codigo), "label": `${cartao.id_empresa.nome}` })
                
                if (cartao.id_colaborador !== null) {
                    setColaborador({ "value": String(cartao.id_colaborador.id_colaborador), "label": `${cartao.id_colaborador.matricula} - ${cartao.id_colaborador.nome_completo}` })
                }
            }

            setLoading(false)
        }

        const getApenasDados = async () => {

            const api = await reqGetNew(ApiEndpoints.despesasCartaoDadosConsultar)

            if (api.status === 200) {
                let empresasAux:any[] = api.data.empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.nome}`
                    }
                ))
                empresasAux.push({ "value": "0", "label": "Não Selecionado" })
                setEmpresasFiltro(empresasAux)

                let colsAux:any[] = api.data.colaboradores.map((item:any, index:any) => (
                    {
                        "value": `${item.id_colaborador}`,
                        "label": `${item.matricula} - ${item.nome_completo}`
                    }
                ))
                colsAux.push({ "value": "0", "label": "Não Selecionado" })
                setColsFiltro(colsAux)
            }
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar Cartão | OB PORTUS"
        } else {
            getApenasDados()
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iDespesaCartaoFormDto = {
            id_cartao: Object.values(cartaoDados).length === 0 ? 0 : cartaoDados.id_cartao,
            id_colaborador: Number(colaborador.value),
            id_empresa: Number(empresa.value),
            num_cartao: form.num_cartao.value,
            nome_portador: form.nome_portador.value,
            ativo: Number(form.status.value),
        }

        if (dados.id_empresa <= 0) {
            form.empresa_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }
        
        if (dados.num_cartao === "") {
            form.num_cartao.nextElementSibling.style.display = "block"
            form.num_cartao.nextElementSibling.innerHTML = "Informe o Número do cartão!"
            formValidado = false
        }
        
        if (dados.num_cartao.length > 16) {
            form.num_cartao.nextElementSibling.style.display = "block"
            form.num_cartao.nextElementSibling.innerHTML = "No máximo 16 caracteres!"
            formValidado = false
        }

        if (dados.nome_portador === "") {
            form.nome_portador.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.id_colaborador! <= 0) {
            dados.id_colaborador = null
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(cartaoDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(cartaoDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iDespesaCartaoFormDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.despesasCartaoCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Cartão cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/cartoes`)
        }
        setLoading(false)

    }

    async function editar(dados: iDespesaCartaoFormDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.despesasCartaoEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cartão editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/cartoes`)
        }

        setLoading(false)
    }

    async function handleExcluirTipo() {
        if (!window.confirm('Tem certeza que deseja excluir o Cartão?')) {
            return
        }

        const dados = {
            id_cartao: cartaoDados.id_cartao
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.despesasCartaoDeletar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cartão excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/cartoes`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">Despesas Cartão</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/despesas/cartoes`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formDespesasCliente' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Empresa:</Form.Label>
                            <Select name='empresa' id='empresa' options={empresasFiltro} value={empresa} placeholder="Selecione a empresa" onChange={
                                    (choice:any) => {
                                        setEmpresa(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                        </Form.Group>
                        <Form.Control name='empresa_aux' type="text" id='empresa_aux' placeholder="" style={{display: "none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione a Empresa!
                        </Form.Control.Feedback>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Colaborador:</Form.Label>
                            <Select name='colaborador' id='colaborador' options={colsFiltro} value={colaborador} placeholder="Selecione o Colaborador" onChange={
                                    (choice:any) => {
                                        setColaborador(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                        </Form.Group>
                        <Form.Control name='colaborador_aux' type="text" id='colaborador_aux' placeholder="" style={{display: "none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione o Colaborador!
                        </Form.Control.Feedback>
                    </Col>

                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Número do cartão:</Form.Label>
                            <Form.Control name='num_cartao' type="number" id='num_cartao' placeholder="" maxLength={16} max={16} />
                            <Form.Control.Feedback type="invalid">
                                Informe o Número do cartão!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Nome completo do Portador:</Form.Label>
                            <Form.Control name='nome_portador' type="text" id='nome_portador' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Select name="status" id="status" aria-label="">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Status!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(cartaoDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(cartaoDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(cartaoDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluirTipo()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default DespesasCartoesFormPage