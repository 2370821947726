import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { iNfServicoDto } from "../dto/nf_servicos"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"

const columnHelper = createColumnHelper<iNfServicoDto>()

const defaultColumns = [
    columnHelper.accessor('cod_serv', {
        header: 'Cod. Serviço',
        size: 10,
    }),
    columnHelper.accessor('descricao', {
        header: 'Razão Social',
        size: 300,
    }),
    columnHelper.accessor(row => `${row.iss_incidencia_municipio_prestador === 1 ? `Retido` : `Tributado`}`, {
        header: 'ISS',
        size: 30,
    }),
]

const defaultOrder:any = []

function NfServicosPage() {
    const [servicos, setServicos] = useState<iNfServicoDto[]>([])

    useEffect(() => {
        document.title = "NF Serviços | OB PORTUS"

        listarDados()
    }, [])

    async function listarDados() {
        const api = await reqGetNew(ApiEndpoints.nfServicosConsultar)

        if (api.status === 200) {
            const clientesAux:iNfServicoDto[] = api.data
            setServicos(clientesAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = servicos[idx]
        const link = `/nf/servico/editar?cod_serv=${reg.cod_serv}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = servicos[idx]
        const link = `/nf/servico/editar?cod_serv=${reg.cod_serv}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Serviços</h1>
                <div className="div-row">
                    <Button onClick={() => {
                        window.location.assign(`/nf/servico/cadastrar`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={servicos} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default NfServicosPage