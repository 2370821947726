import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput } from "../../../components/funcForInput"
import { iDespesaClienteDto } from "../dto/despesas_clientes"

function DespesasClienteFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [clienteDados, setClienteDados] = useState<iDespesaClienteDto | any>({})

    useEffect(() => {
        document.title = "Cadastrar Cliente | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                id_cliente: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.despesasClienteConsultar, dados)

            if (api.status === 200) {
                const cliente:iDespesaClienteDto = api.data
                setClienteDados(cliente)
                defineValueInInput('id_cliente', cliente.id_cliente)
                defineValueInInput('descricao', cliente.descricao)
                defineValueInInput('id_centro_custo', cliente.id_centro_custo)
                defineValueInInput('status', cliente.ativo)
            }

            setLoading(false)
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar Cliente | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iDespesaClienteDto = {
            id_cliente: Object.values(clienteDados).length === 0 ? 0 : clienteDados.id_cliente,
            descricao: form.descricao.value,
            id_centro_custo: Number(form.id_centro_custo.value),
            ativo: Number(form.status.value)
        }

        // cod_serv: Object.values(clienteDados).length > 0 ? clienteDados?.cod_serv : form.cod_serv.value,
        //     descricao: form.descricao.value,
        //     iss_incidencia_municipio_prestador: form.iss.value,
        
        if (dados.descricao === "") {
            form.descricao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.id_centro_custo! <= 0 || dados.id_centro_custo === null) {
            dados.id_centro_custo = null
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(clienteDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(clienteDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iDespesaClienteDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.despesasClienteCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Cliente cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/clientes`)
        }
        setLoading(false)

    }

    async function editar(dados: iDespesaClienteDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.despesasClienteEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cliente editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/clientes`)
        }

        setLoading(false)
    }

    async function handleExcluirTipo() {
        if (!window.confirm('Tem certeza que deseja excluir o Cliente?')) {
            return
        }

        const dados = {
            id_cliente: clienteDados.id_cliente
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.despesasClienteDeletar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cliente excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/clientes`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">Despesas Cliente</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/despesas/clientes`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formDespesasCliente' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms" style={{display: Object.values(clienteDados).length > 0 ? 'flex' : 'none', }}>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Cod. Cliente:</Form.Label>
                            <Form.Control name='id_cliente' type="number" id='id_cliente' placeholder="" readOnly disabled/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Descrição!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Cod. Centro de Custo:</Form.Label>
                            <Form.Control name='id_centro_custo' type="number" id='id_centro_custo' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Cod. do Centro de Custo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Select name="status" id="status" aria-label="">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Status!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(clienteDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(clienteDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(clienteDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluirTipo()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default DespesasClienteFormPage