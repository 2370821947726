import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, InputGroup, Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardLink } from 'react-bootstrap'
import Select from 'react-select'
import $ from 'jquery'
import 'jquery-mask-plugin'
import { getCookie } from '../../../config/cookies'
import { toast } from 'react-toastify'
import { ApiEndpoints, reqPut, reqPatch, reqPostNew } from '../../../config/apiClient';
import dayjs from 'dayjs';

function sleep(ms:any) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const customStyleForRadioDiv:React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '0px 10px 0px 0px',
    padding: '0px'
}

const customStyleForRadioDivInput:React.CSSProperties = {
    padding: '0px',
    margin: '0px',
    cursor: 'pointer'
}

const customStyleForRadioDivLabel:React.CSSProperties = {
    padding: '2px 0px 0px 10px',
    margin: '0px',
    cursor: 'pointer'
}

const customStyleForStatusTextPendente:React.CSSProperties = {
    backgroundColor: '#023e8a',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.4em',
    width: 'fit-content',
    padding: '0.2em',
    marginBottom: "5px",
}

const customStyleForStatusTextAprovado:React.CSSProperties = {
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.4em',
    width: 'fit-content',
    padding: '0.2em',
    marginBottom: "5px",
}

const customStyleForStatusTextReprovado:React.CSSProperties = {
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.4em',
    width: 'fit-content',
    padding: '0.2em',
    marginBottom: "5px",
}

function getValorFromInput(id:string) {
    let ele:any = document.getElementById(id)
    let valor_string:string = String(ele.value)
    let valor:number = Number(valor_string.replaceAll(".", "").replaceAll(",", "."))
    return valor
}

function getValorFromString(text:string) {
    let valor:number = Number(text.replaceAll(".", "").replaceAll(",", "."))
    return valor
}

function removeAllErrorMsg() {
    let divs:any = document.querySelectorAll(".invalid-feedback")
    for (let ele of divs) {
        ele.style.display = "none"
    }
}

function defineValueInInput(id:string, value:any) {
    let ele :any = document.getElementById(id)
    if (ele === null) {
        return
    }
    ele.value = value
}

export default function ModalCusto(props:any) {

    $('.inputMoney').mask('#.##0,00', {
        reverse: true,
        placeholder: '0,00'
    })

    function formatarStringInReal(valor:string):string {
        let num = Number(valor).toFixed(2)
        defineValueInInput("valor_aux", num)
        return $("#valor_aux").masked(num)
        // console.log($("#valor_aux").masked(num))
        // return "R$ " + inputMoney[0].jQuery371056313495913025612.mask.getMaskedVal()
    }

    const [dadosCusto, setDadosCusto] = useState<any>({})

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [veiculoID, setVeiculoID] = useState({
        "value": "0",
        "label": "Selecione Veículo"
    })
    const [clienteID, setClienteID] = useState({
        "value": "0",
        "label": "Selecione o Cliente"
    })
    const [totalCusto, setTotalCusto] = useState<string>('R$ 0,00')

    const [tiposCustosForm, setTiposCustosForm] = useState<any[]>([])
    const [tiposManutencoesForm, setTiposManutencoesForm] = useState<any[]>([])
    const [anexosForm, setAnexosForm] = useState<any[]>([])
    
    const [btnDesfazerVisivel, setBtnDesfazerVisivel] = useState<boolean>(true)

    useEffect(() => {
        $('.inputMoney').mask('#.##0,00', {
            reverse: true,
            placeholder: '0,00'
        })

        getCustoDados()
        // eslint-disable-next-line
    }, [props.custoDados])

    async function getCustoDados() {
        if (Object.values(props.custoDados).length === 0) {
            return
        }

        let custoDados:any = props.custoDados
        console.log("MODAL PROPS")
        console.log(props.custoDados)
        setDadosCusto(props.custoDados)

        // let custo = api.data
        let tipo_custo:any = document.getElementById(`tipo_custo_${custoDados.id_tipo_custo.id_tipo_custo}`)
        tipo_custo.checked = true
        setVeiculoID({
            "value": `${custoDados.id_veiculo.id_veiculo}`,
            "label": `${custoDados.id_veiculo.veiculo} - ${custoDados.id_veiculo.placa}`
        })
        setClienteID({
            "value": `${custoDados.id_cliente.id_cliente}`,
            "label": `(${custoDados.id_cliente.id_centro_custo}) ${custoDados.id_cliente.descricao}`
        })
        defineValueInInput("data_hora", dayjs(custoDados.data_hora_custo).format("YYYY-MM-DDTHH:mm"))
        defineValueInInput("hodometro", custoDados.hodometro)
        setTotalCusto("R$ " + formatarStringInReal(custoDados.valor_total))

        let itensManutencao = []
        let itensDespesas = []

        for (let reg of custoDados.custos) {
            if (reg.id_categoria === 1) {
                itensDespesas.push({
                    "value": `${reg.id_tipo_despesa.id_tipo_despesa}`,
                    "label": `${reg.id_tipo_despesa.tipo_despesa}`
                })
            }
            if (reg.id_categoria === 2) {
                itensManutencao.push({
                    "value": `${reg.id_tipo_manutencao.id_tipo_manutencao}`,
                    "label": `${reg.id_tipo_manutencao.tipo_manutencao}`
                })
            }
        }

        setTiposCustosForm(itensDespesas)
        setTiposManutencoesForm(itensManutencao)
        setAnexosForm(custoDados.anexos)

        if (custoDados.data_revisado !== null) {
            const diff = dayjs().diff(dayjs(custoDados.data_revisado), 'days')
            if (diff >= 30) {
                setBtnDesfazerVisivel(false)
            }
        }

        await sleep(500)

        for (let reg of custoDados.custos) {
            if (reg.id_categoria === 1) {
                defineValueInInput(`despesa_${reg.id_tipo_despesa.tipo_despesa}_valor`, formatarStringInReal(reg.valor))
                if (reg.id_tipo_despesa.id_tipo_despesa === 1) {
                    defineValueInInput(`despesa_${reg.id_tipo_despesa.tipo_despesa}_litros`, reg.litros)
                    defineValueInInput(`despesa_${reg.id_tipo_despesa.tipo_despesa}_valor_litro`, formatarStringInReal(reg.valor_litro))
                    defineValueInInput(`despesa_${reg.id_tipo_despesa.tipo_despesa}_tipo_combustivel`, reg.id_tipo_combustivel.id_tipo_combustivel)
                }
                defineValueInInput(`despesa_${reg.id_tipo_despesa.tipo_despesa}_descricao`, reg.descricao)
            }
            if (reg.id_categoria === 2) {
                defineValueInInput(`manutencao_${reg.id_tipo_manutencao.tipo_manutencao}_valor`, formatarStringInReal(reg.valor))
                defineValueInInput(`manutencao_${reg.id_tipo_manutencao.tipo_manutencao}_descricao`, reg.descricao)
                defineValueInInput(`manutencao_${reg.id_tipo_manutencao.tipo_manutencao}_vencido_por`, reg.vencido_por)
                defineValueInInput(`manutencao_${reg.id_tipo_manutencao.tipo_manutencao}_hodometro_ultima`, reg.hodometro_ultima)
                defineValueInInput(`manutencao_${reg.id_tipo_manutencao.tipo_manutencao}_data_ultima`, reg.data_ultima)
            }
        }

    }

    function calcularTotalCustos() {
        let total:number = 0
        for (let reg of tiposManutencoesForm) {
            let value:number = getValorFromInput(`manutencao_${reg.label}_valor`)
            total = total + value
        }
        for (let reg of tiposCustosForm) {
            let value:number = getValorFromInput(`despesa_${reg.label}_valor`)
            total = total + value
        }
        let text:string = $(`.inputMoney`).masked(String(total.toFixed(2)))
        setTotalCusto(`R$ ${text}`)

        return total
    }

    function limparCampos() {
        setDadosCusto({})
        setTiposCustosForm([])
        setTiposManutencoesForm([])
        setAnexosForm([])
        setTotalCusto(`R$ 0,00`)
        setVeiculoID({
            "value": "0",
            "label": "Selecione Veículo"
        })
        setClienteID({
            "value": "0",
            "label": "Selecione Cliente"
        })
        setBtnDesfazerVisivel(true)
    }

    function getCustoId(): number | null {
        if (Object.values(props.custoDados).length === 0) {
            return null
        }

        return props.custoDados.id_custo
    }

    function handleClickFechar(event:any): void {
        limparCampos()

        props.onHide()
    }

    function handleClickBtnDelete(event:any): void {
        if (!window.confirm("Tem certeza que deseja excluir esses registros?")) {
            return
        }

        deletar({
            "id_custo": props.custoDados['id_custo'],
        })
    }

    function handleClickBtnDeletarAnexo(event:any, id_anexo: number): void {
        if (!window.confirm("Tem certeza que deseja excluir o anexo?")) {
            return
        }

        deletarAnexo({
            "id_anexo": id_anexo,
        })
    }

    function handleSubmit(): void {

        let validado = true
        const form:any = document.getElementById("formCusto")

        removeAllErrorMsg()

        let dados:any = {
            "id_custo": getCustoId(),
            "id_tipo_custo": Number(form.tipo_custo.value),
            "id_usuario": Number(getCookie('id_user')),
            "id_colaborador": null,
            "id_veiculo": Number(form.id_veiculo.value),
            "id_cliente": Number(form.id_cliente.value),
            "data_hora_custo": String(`${form.data_hora.value}:00`).replaceAll("T", " "),
            "hodometro": Number(form.hodometro.value),
            "valor_total": calcularTotalCustos(),
            "items_manutencao": [],
            "items_despesas": [],
            "items": []
        }
        let dados_anexos = {
            comprovante: {
                id_tipo_anexo: 1,
                nome_original: null,
                extensao: null,
                arquivo: null,
            },
            foto_painel: {
                id_tipo_anexo: 2,
                nome_original: null,
                extensao: null,
                arquivo: null,
            },
        }

        if (dados.id_tipo_custo === 0) {
            validado = false
            form.id_tipo_custo_aux.nextElementSibling.innerText = "Informe o tipo de custo!"
            form.id_tipo_custo_aux.nextElementSibling.style.display = "block"
        }

        if (dados.id_veiculo === 0) {
            validado = false
            form.id_veiculo_aux.nextElementSibling.innerText = "Informe o veículo!"
            form.id_veiculo_aux.nextElementSibling.style.display = "block"
        }

        if (dados.id_cliente === 0) {
            validado = false
            form.id_cliente_aux.nextElementSibling.innerText = "Informe o cliente!"
            form.id_cliente_aux.nextElementSibling.style.display = "block"
        }

        if (dados.data_hora_custo === ":00") {
            validado = false
            form.data_hora.nextElementSibling.style.display = "block"
        }

        if (dados.hodometro === 0) {
            
            if (!window.confirm("O campo do Hodômetro está zerado, deseja continuar?")) {
                validado = false
                form.hodometro.nextElementSibling.style.display = "block"
            }

        }

        if (dados.id_tipo_custo === 1) {
            if (tiposCustosForm.length === 0) {
                validado = false
                form.idx_tipos_custos_aux.nextElementSibling.innerText = "Informe quais foram as despesas!"
                form.idx_tipos_custos_aux.nextElementSibling.style.display = "block"
            }

            if (tiposManutencoesForm.length > 0) {
                let okay = window.confirm("Registros do tipo despesa, porém possui itens de manutenção informados, deseja continuar?")
                if (!okay) {
                    validado = false
                    form.id_tipo_custo_aux.nextElementSibling.innerText = "Caso for adicionar itens de manutenção, selecione qual foi o tipo de manutenção!"
                    form.id_tipo_custo_aux.nextElementSibling.style.display = "block"
                }
            }
        }

        if (dados.id_tipo_custo === 2 || dados.id_tipo_custo === 3) {
            if (tiposManutencoesForm.length === 0) {
                validado = false
                form.idx_tipos_manutencoes_aux.nextElementSibling.innerText = "Informe os itens de manutenções!"
                form.idx_tipos_manutencoes_aux.nextElementSibling.style.display = "block"
            }

            if (tiposManutencoesForm.length === 0 && tiposCustosForm.length > 0) {
                let okay = window.confirm("Registros do tipo manutenção, porém possui apenas itens de despesa informados, deseja continuar?")
                if (!okay) {
                    validado = false
                    form.id_tipo_custo_aux.nextElementSibling.innerText = "Caso for adicionar apenas itens de despesas, selecione o tipo 'Despesa'!"
                    form.id_tipo_custo_aux.nextElementSibling.style.display = "block"
                }
            }
        }

        let todos_itens = []

        let itens_manutencao = []
        for (let manutencao of tiposManutencoesForm) {
            let reg = {
                "id_tipo_manutencao": Number(manutencao.value),
                "valor": form[`manutencao_${manutencao.label}_valor`].value,
                "descricao": form[`manutencao_${manutencao.label}_descricao`].value,
                "vencido_por": Number(form[`manutencao_${manutencao.label}_vencido_por`].value),
                "hodometro_ultima": Number(form[`manutencao_${manutencao.label}_hodometro_ultima`].value),
                "data_ultima": form[`manutencao_${manutencao.label}_data_ultima`].value,
                "id_categoria": 2
            }
            if (reg.valor === "") {
                validado = false
                form[`manutencao_${manutencao.label}_valor`].nextElementSibling.style.display = "block"
            }
            if (reg.descricao === "") {
                validado = false
                form[`manutencao_${manutencao.label}_descricao`].nextElementSibling.style.display = "block"
            }
            if (reg.vencido_por === 0) {
                validado = false
                form[`manutencao_${manutencao.label}_vencido_por`].nextElementSibling.style.display = "block"
            }
            if (reg.data_ultima === "") {
                reg.data_ultima = null
            }
            reg.valor = getValorFromString(reg.valor)
            itens_manutencao.push(reg)
            todos_itens.push(reg)
        }
        dados.items_manutencao = itens_manutencao

        let itens_despesas = []
        for (let despesa of tiposCustosForm) {
            let reg:any = {
                "id_tipo_despesa": Number(despesa.value),
                "valor": form[`despesa_${despesa.label}_valor`].value,
                "descricao": form[`despesa_${despesa.label}_descricao`].value,
                "litros": null,
                "valor_litro": null,
                "id_tipo_combustivel": null,
                "id_categoria": 1
            }
            if (reg.valor === "") {
                validado = false
                form[`despesa_${despesa.label}_valor`].nextElementSibling.style.display = "block"
            }
            if (reg.descricao === "") {
                validado = false
                form[`despesa_${despesa.label}_descricao`].nextElementSibling.style.display = "block"
            }
            if (reg.id_tipo_despesa === 1) {
                reg.litros = form[`despesa_${despesa.label}_litros`].value
                if (reg.litros === "0" || Number(reg.litros) === 0 || reg.litros === "") {
                    validado = false
                    form[`despesa_${despesa.label}_litros`].nextElementSibling.style.display = "block"
                }

                reg.valor_litro = form[`despesa_${despesa.label}_valor_litro`].value
                reg.valor_litro = String(reg.valor_litro).replaceAll(".", "").replaceAll(",", ".")
                if (reg.valor_litro === "") {
                    validado = false
                    form[`despesa_${despesa.label}_valor_litro`].nextElementSibling.style.display = "block"
                }

                reg.id_tipo_combustivel = Number(form[`despesa_${despesa.label}_tipo_combustivel`].value)
                if (reg.id_tipo_combustivel === 0) {
                    validado = false
                    form[`despesa_${despesa.label}_tipo_combustivel`].nextElementSibling.style.display = "block"
                }
            }
            reg.valor = getValorFromString(reg.valor)
            itens_despesas.push(reg)
            todos_itens.push(reg)
        }
        dados.items_despesas = itens_despesas
        dados.items = todos_itens

        if (dados.id_custo === null) {
            const comprovante = form.comprovante.files
            const foto_painel = form.foto_painel.files

            // console.log("comprovante")
            // console.log(comprovante)
            // console.log("foto_painel")
            // console.log(foto_painel)

            let anexos_selecionados = true

            if (comprovante.length === 0) {
                form[`comprovante`].nextElementSibling.style.display = "block"
                anexos_selecionados = false
            } else {
                dados_anexos.comprovante.arquivo = comprovante[0]
            }
            if (foto_painel.length === 0) {
                form[`foto_painel`].nextElementSibling.style.display = "block"
                anexos_selecionados = false
            } else {
                dados_anexos.foto_painel.arquivo = foto_painel[0]
            }

            if (!anexos_selecionados) {
                if (window.confirm("Deseja adicionar os anexos AGORA?")) {
                    validado = false
                }
            }
        }

        console.log(dados)

        if (!validado) {
            toast.warning('Verifique se todos os campos estão corretos!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        if (Object.values(props.custoDados).length === 0) {
            cadastrar(dados, dados_anexos)
        }

        if (Object.values(props.custoDados).length > 0) {
            editar(dados, dados_anexos)
        }

    }

    async function handleClickBtnUploadArquivo(event:any): Promise<void> {

        const form:any = document.getElementById("formCusto")

        const arquivos:any[] = form.anexo.files
        if (arquivos.length === 0) {
            toast.warning('Nenhum arquivo foi selecionado!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        setIsLoading(true)
        
        const dados = {
            id_tipo_anexo: Number(form.anexo_tipo.value),
            nome_original: null,
            extensao: null,
            arquivo: arquivos[0],
        }
        
        const anexos = await uploadArquivo(props.custoDados.id_custo, dados)

        if (anexos.length > 0) {
            setAnexosForm(anexos)
            props.onUpdate()
        }

        form.anexo.value = ""

        setIsLoading(false)

    }

    async function uploadArquivo(id_custo:number, anexo:any) {
        const token = getCookie("token")
        let dados = new FormData()
        
        dados.append('id_custo', id_custo.toString())
        dados.append('id_tipo_anexo', String(anexo.id_tipo_anexo))

        let arquivo = anexo.arquivo
        dados.append('nome_original', String(arquivo.name))

        let extensao:string = "pdf"
        if (arquivo.type === "image/jpeg" || arquivo.type === "image/jpg") {
            extensao = "jpg"
        }
        if (arquivo.type === "image/png") {
            extensao = "png"
        }
        dados.append('extensao', extensao)

        dados.append("anexo", arquivo)

        try {
            let api:Response
            let apiData = await fetch(
                ApiEndpoints.frotaUploadAnexo.endpoint,
                {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    body: dados
                }
            ).then(response => {
                api = response
                return response.json()
            })
            if (api!.status === 200) {
                console.log(apiData)
                toast.success("Arquivo enviado com sucesso!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark',
                    autoClose: 500,
                })
                return apiData
            } else {
                toast.warn(String(apiData.message), {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        } catch (error) {
            console.log(error)
            toast.warn('Não foi possível fazer o upload, tente novamente mais tarde ou contate o suporte!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        return []

    }

    async function cadastrar(dados:any, dados_anexos:any) {

        setIsLoading(true)

        const api = await reqPut(ApiEndpoints.frotaCadastro, dados)

        if (api.status === 201) {
            console.log(api.data)

            const id_custo = api.data.id_custo
            if (dados_anexos.comprovante.arquivo !== null) {
                await uploadArquivo(id_custo, dados_anexos.comprovante)
            }
            if (dados_anexos.foto_painel.arquivo !== null) {
                await uploadArquivo(id_custo, dados_anexos.foto_painel)
            }

            toast.success('Registros Cadastrados com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark',
                autoClose: 1200,
            })

            limparCampos()

            props.onHide()
            props.onUpdate()
            setIsLoading(false)
            return
        }

        setIsLoading(false)

    }

    async function editar(dados:any, dados_anexos:any) {

        setIsLoading(true)

        // await sleep(2000)

        const api = await reqPatch(ApiEndpoints.frotaEditar, dados)

        if (api.status === 200) {

            toast.success('Registros Editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark',
                autoClose: 1200,
            })
            props.onUpdate()
            setIsLoading(false)
            return

        }

        setIsLoading(false)

    }

    async function deletar(dados:any) {

        setIsLoading(true)

        const api = await reqPostNew(ApiEndpoints.frotaDeletar, dados)

        if (api.status === 200) {

            toast.success('Registros Excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            limparCampos()

            props.onHide()
            props.onUpdate()
            setIsLoading(false)
            return

        }

        setIsLoading(false)

    }

    async function deletarAnexo(dados:any) {

        setIsLoading(true)

        const api = await reqPostNew(ApiEndpoints.frotaDeletarAnexo, dados)

        if (api.status === 200) {

            toast.success('Anexo Excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            setAnexosForm(api.data)
            props.onUpdate()

            setIsLoading(false)
            return

        }

        setIsLoading(false)

    }

    async function handleClickRevisar(id_status: number) {
        if (!window.confirm("Tem certeza que deseja revisar?")) {
            return
        }

        let dados = {
            id_custo: dadosCusto.id_custo,
            id_status: id_status,
            id_usuario: Number(getCookie('id_user')),
        }

        setIsLoading(true)

        const api = await reqPatch(ApiEndpoints.frotaRevisar, dados)

        if (api.status === 200) {

            toast.success('Revisado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            setDadosCusto(api.data)
            props.onUpdate()

        }

        setIsLoading(false)

    }

    return (
        <Modal
            show={props.show}
            size="lg"
            id="modal_custo"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header style={{display: "block"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <b>{Object.values(dadosCusto).length > 0 ? `Editar Registros #${dadosCusto.id_custo}` : 'Cadastrar novos registros'}</b>
                    </Modal.Title>
                    <Button onClick={handleClickFechar}>Fechar</Button>
                </div>
                <Col>
                    {
                        Object.values(dadosCusto).length > 0 &&
                        <div>
                            <p>Criado em {dayjs(dadosCusto.data_criacao).format("DD/MM/YYYY HH:mm:ss")} por {dadosCusto.id_usuario === null ? dadosCusto.id_colaborador.nome_completo : dadosCusto.id_usuario.nome}</p>
                        </div>
                    }
                </Col>
                <Col>
                    {
                        Object.values(dadosCusto).length > 0 &&
                        <div style={{ marginTop: '20px' }}>
                            {/* <p style={{ fontSize: '14px' }}>Status</p> */}
                            { dadosCusto.id_status.id_status === 1 && <p style={customStyleForStatusTextPendente}>{dadosCusto.id_status.descricao}</p> }
                            { dadosCusto.id_status.id_status === 2 && <p style={customStyleForStatusTextAprovado}>{dadosCusto.id_status.descricao}</p> }
                            { dadosCusto.id_status.id_status === 3 && <p style={customStyleForStatusTextReprovado}>{dadosCusto.id_status.descricao}</p> }
                            {
                                dadosCusto.id_status.id_status !== 1 &&
                                <p style={{ fontSize: "1em" }}>{dayjs(dadosCusto.data_revisado).format("DD/MM/YYYY HH:mm:ss")} por {dadosCusto.id_usuario_revisado.nome}</p>
                            }
                            { (dadosCusto.id_status.id_status !== 1 && btnDesfazerVisivel) && <button type="button" onClick={() => {handleClickRevisar(1)}} className="btn btn-link" style={{padding: "0px", fontSize: "0.8em", marginTop: "5px"}}>Desfazer</button> }
                        </div>
                    }
                </Col>
            </Modal.Header>
            <Modal.Body>
                {/* {JSON.stringify(props.custoDados)} */}
                <Form id='formCusto' onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className='col-6' style={{ display: 'flex', verticalAlign: 'middle' }}>
                        <div className="form-check" style={customStyleForRadioDiv}>
                            <input className="form-check-input" type="radio" name="tipo_custo" id="tipo_custo_1" value={1} style={customStyleForRadioDivInput} />
                            <label className="form-check-label" htmlFor="tipo_custo_1" style={customStyleForRadioDivLabel}>Despesa</label>
                        </div>
                        <div className="form-check" style={customStyleForRadioDiv}>
                            <input className="form-check-input" type="radio" name="tipo_custo" id="tipo_custo_2" value={2} style={customStyleForRadioDivInput} />
                            <label className="form-check-label" htmlFor="tipo_custo_2" style={customStyleForRadioDivLabel}>Manutenção Corretiva</label>
                        </div>
                        <div className="form-check" style={customStyleForRadioDiv}>
                            <input className="form-check-input" type="radio" name="tipo_custo" id="tipo_custo_3" value={3} style={customStyleForRadioDivInput} />
                            <label className="form-check-label" htmlFor="tipo_custo_3" style={customStyleForRadioDivLabel}>Manutenção Planejada</label>
                        </div>
                        <Form.Control name='id_tipo_custo_aux' type="text" id='id_tipo_custo_aux' placeholder="..." required style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Informe o tipo de custo!
                        </Form.Control.Feedback>
                    </div>
                    {
                        Object.values(dadosCusto).length > 0 &&
                        <div className='col-6' style={{ display: dadosCusto.id_status.id_status === 1 ? 'block' : 'none' }}>
                            <Button variant='success' onClick={() => {handleClickRevisar(2)}} disabled={isLoading} style={{ marginRight: "15px" }}>APROVAR</Button>
                            <Button variant='danger' onClick={() => {handleClickRevisar(3)}} disabled={isLoading} style={{ marginRight: "15px" }}>REPROVAR</Button>
                        </div>
                    }
                    
                </div>
                <br></br>
                <div className='row'>
                    <div className='col-3'>
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Veículo</Form.Label>
                            <Select name='id_veiculo' value={veiculoID} id='id_veiculo' options={props.veiculosFiltro} placeholder="Selecione Veículo" onChange={
                                (choice:any) => {
                                    setVeiculoID(choice)
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='id_veiculo_aux' type="text" id='id_veiculo_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe o veículo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='col-3'>
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Cliente</Form.Label>
                            <Select name='id_cliente' value={clienteID} id='id_cliente' options={props.clientesFiltro} placeholder="Selecione o Cliente" onChange={
                                (choice:any) => {
                                    setClienteID(choice)
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='id_cliente_aux' type="text" id='id_cliente_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cliente!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Data Manutenção</Form.Label>
                            <Form.Control name='data_hora' type="datetime-local" id='data_hora' placeholder="DD/MM/YYYY HH:mm:ss"/>
                            <Form.Control.Feedback type="invalid">
                                Informe a data da manutenção!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Hodômetro</Form.Label>
                            <Form.Control name='hodometro' type="number" id='hodometro' defaultValue={0} />
                            <Form.Control.Feedback type="invalid">
                                Informe o Hodômetro do veículo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group className="mt-0 mb-0">
                            <Form.Label>Valor Total</Form.Label>
                            <div style={{
                                height: '38px',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                fontSize: '1.3em'
                            }}>
                                <p id='text_total_custo'>{totalCusto}</p>
                                <input type='text' name='valor_aux' id='valor_aux' className='inputMoney' style={{display:'none'}} />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <h2 style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Custos</h2>
                <br></br>
                <div className='row'>
                    <div className='col-6'>

                        <Form.Group className="mt-0 mb-0">
                            {/* <Form.Label>Selecione as despesas:</Form.Label> */}
                            <Select name='idx_tipos_custos' id='idx_tipos_custos' value={tiposCustosForm} options={props.tiposCustosFiltro} isMulti closeMenuOnSelect={false} placeholder="Selecione as despesas:" onChange={
                                (choice:any) => {
                                    setTiposCustosForm(choice)
                                    // calcularTotalCustos()
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6",
                                }),
                            }} />
                            <Form.Control name='idx_tipos_custos_aux' type="text" id='idx_tipos_custos_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe quais foram as despesas!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className='col-6'>

                        <Form.Group className="mt-0 mb-0">
                            {/* <Form.Label>Selecione as despesas:</Form.Label> */}
                            <Select name='idx_tipos_manutencoes' id='idx_tipos_manutencoes' value={tiposManutencoesForm} options={props.tiposManutencaoFiltro} isMulti closeMenuOnSelect={false}  placeholder="Selecione as manutenções:" onChange={
                                (choice:any) => {
                                    setTiposManutencoesForm(choice)
                                    // calcularTotalCustos()
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6",
                                }),
                            }} />
                            <Form.Control name='idx_tipos_manutencoes_aux' type="text" id='idx_tipos_manutencoes_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe os itens de manutenções!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>
                </div>
                <br></br>
                <div id='divs_manutencao_itens'>
                    {
                        tiposManutencoesForm.map(
                            (item, index) => (
                                <div key={index} id={`manutencao_${item.label}_div`}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p style={{height: '30px', padding: '7px', background: '#052C65', width: 'fit-content', color: 'white'}}>Manutenção - {item.label}:</p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <InputGroup className="mt-0 mb-3">
                                                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                                                <Form.Control name={`manutencao_${item.label}_valor`} type="text" id={`manutencao_${item.label}_valor`} placeholder="0,00" className='inputMoney' autoComplete='off' onKeyUp={(value) => {
                                                    calcularTotalCustos()
                                                }} required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Informe o valor dessa manutenção!
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        <div className='col-4'>
                                            <InputGroup className="mt-0 mb-3">
                                                <InputGroup.Text>Descrição</InputGroup.Text>
                                                <Form.Control id={`manutencao_${item.label}_descricao`} name={`manutencao_${item.label}_descricao`} as="textarea" aria-label="With textarea" style={{minHeight: '38px', height: '38px', maxHeight: '117px'}} />
                                                <Form.Control.Feedback type="invalid">
                                                    Informe a descrição!
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <Form.Group className="mt-0 mb-3">
                                                <Form.Select id={`manutencao_${item.label}_vencido_por`} name={`manutencao_${item.label}_vencido_por`} aria-describedby={`manutencao_${item.label}_vencido_por_help_text`} aria-label="Selecione">
                                                    <option value="0">Não selecionado</option>
                                                    <option value="1">Km</option>
                                                    <option value="2">Tempo</option>
                                                    <option value="3">Km e Tempo</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Selecione uma opção!
                                                </Form.Control.Feedback>
                                                <Form.Text id={`manutencao_${item.label}_vencido_por_help_text`} muted>
                                                    Vencido por
                                                </Form.Text>
                                            </Form.Group>
                                        </div>
                                        <div className='col-2'>
                                            <Form.Group className="mt-0 mb-3">
                                                <Form.Control name={`manutencao_${item.label}_hodometro_ultima`} type="number" id={`manutencao_${item.label}_hodometro_ultima`} placeholder="0" defaultValue={0} autoComplete='off' aria-describedby={`manutencao_${item.label}_hodometro_ultima_help_text`} required/>
                                                <Form.Control.Feedback type="invalid">
                                                    O valor do hodômetro é inválido!
                                                </Form.Control.Feedback>
                                                <Form.Text id={`manutencao_${item.label}_hodometro_ultima_help_text`} muted>
                                                    Hodômetro Última
                                                </Form.Text>
                                            </Form.Group>
                                        </div>
                                        <div className='col-2'>
                                            <Form.Group className="mt-0 mb-3">
                                                <Form.Control name={`manutencao_${item.label}_data_ultima`} type="date" id={`manutencao_${item.label}_data_ultima`} autoComplete='off' aria-describedby={`manutencao_${item.label}_data_ultima_help_text`} required/>
                                                <Form.Control.Feedback type="invalid">
                                                    A data informado é inválida!
                                                </Form.Control.Feedback>
                                                <Form.Text id={`manutencao_${item.label}_data_ultima_help_text`} muted>
                                                    Data Última
                                                </Form.Text>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
                <br></br>
                <div id='divs_custo_itens'>
                    {
                        tiposCustosForm.map(
                            (item, index) => (
                                <div key={index} id={`despesa_${item.label}_div`}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p style={{height: '30px', padding: '7px', background: '#E2E3E5', width: 'fit-content', color: 'black'}}>Despesa - {item.label}:</p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <InputGroup className="mt-0 mb-3">
                                                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                                                <Form.Control name={`despesa_${item.label}_valor`} type="text" id={`despesa_${item.label}_valor`} placeholder="0,00" className='inputMoney' autoComplete='off' onKeyUp={(value) => {
                                                    calcularTotalCustos()
                                                }}  required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Informe o valor dessa despesa!
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        {
                                            Number(item.value) === 1 &&
                                            <div className='col-2'>
                                                <InputGroup className="mt-0 mb-3">
                                                    <InputGroup.Text id="basic-addon1">Litros</InputGroup.Text>
                                                    <Form.Control name={`despesa_${item.label}_litros`} type="number" id={`despesa_${item.label}_litros`} autoComplete='off' placeholder='0.00' required/>
                                                    <Form.Control.Feedback type="invalid">
                                                        Informe a quantidade de litros!
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </div>
                                        }
                                        <div className='col-2' style={{display: Number(item.value) === 1 ? 'flex' : 'none',}}>
                                            <InputGroup className="mt-0 mb-3">
                                                <InputGroup.Text id="basic-addon1">$/L</InputGroup.Text>
                                                <Form.Control name={`despesa_${item.label}_valor_litro`} type="text" id={`despesa_${item.label}_valor_litro`} placeholder='0,00' className='inputMoney' autoComplete='off' onKeyUp={(value) => {
                                                    calcularTotalCustos()
                                                }} required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Informe o preço por litro!
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2' style={{display: Number(item.value) === 1 ? 'block' : 'none',}}>
                                            <Form.Select id={`despesa_${item.label}_tipo_combustivel`} name={`despesa_${item.label}_tipo_combustivel`} aria-describedby={`despesa_${item.label}_tipo_combustivel_help_text`} aria-label="Selecione" defaultValue={"0"}>
                                                <option value="0">Não selecionado</option>
                                                <option value="1">Gasolina Comum</option>
                                                <option value="2">Gasolina Aditivada</option>
                                                <option value="3">Gasolina Premium</option>
                                                <option value="4">Diesel Comum</option>
                                                <option value="5">Diesel S10</option>
                                                <option value="6">Etanol Comum</option>
                                                <option value="7">Etanol Aditivado</option>
                                                <option value="8">Gás Natural</option>
                                                <option value="9">ARLA</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Informe o Tipo de Combustível!
                                            </Form.Control.Feedback>
                                            <Form.Text id={`despesa_${item.label}_tipo_combustivel_help_text`} muted>
                                                Tipo de Combustível
                                            </Form.Text>
                                        </div>
                                        <div className='col-4'>
                                        <InputGroup className="mt-0 mb-3">
                                            <InputGroup.Text>Descrição</InputGroup.Text>
                                            <Form.Control id={`despesa_${item.label}_descricao`} name={`despesa_${item.label}_descricao`} as="textarea" aria-label="With textarea" style={{minHeight: '38px', height: '38px', maxHeight: '117px'}} />
                                            <Form.Control.Feedback type="invalid">
                                                Informe uma descrição!
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
                
                <Button variant='success' onClick={() => {handleSubmit()}} disabled={isLoading} about='criar_registros' style={{ marginRight: "15px" }}>Salvar Registros</Button>

                <div id='div_anexar_arquivos' style={{display: Object.values(props.custoDados).length > 0 ? "none" : "block"}}>
                <hr></hr>
                    <Row>
                        <Col className='col-6'>
                            <Form.Group>
                                <Form.Label>Anexar comprovante:</Form.Label>
                                <Form.Control type="file" name="comprovante" id="comprovante" aria-label="Selecione o Comprovante" accept="image/png, image/jpeg, .docx, .pdf"></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Necessário enviar comprovante!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className='col-6'>
                            <Form.Group>
                                <Form.Label>Anexar foto do Painel do Veículo:</Form.Label>
                                <Form.Control type="file" name="foto_painel" id="foto_painel" aria-label="Selecione a Foto" accept="image/png, image/jpeg, .docx, .pdf"></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Necessário enviar a foto do Painel do Veículo!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                </div>

                <div id='div_adicionar_anexo' style={{display: Object.values(props.custoDados).length > 0 ? 'block' : 'none',}}>
                <hr></hr>
                    <Row>
                        <Col className="col-6">
                            <Form.Group>
                                <Form.Label>Anexar arquivo:</Form.Label>
                                <Form.Control type="file" name="anexo" id="anexo" aria-label="Selecione o Anexo" accept="image/png, image/jpeg, .docx, .pdf"></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Necessário enviar anexo!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className='col-4'>
                            <Form.Label>Tipo do anexo:</Form.Label>
                            <Form.Select id="anexo_tipo" name="anexo_tipo" aria-describedby="anexo_tipo_help_text" aria-label="Selecione" defaultValue={"0"}>
                                <option value="1">Comprovante</option>
                                <option value="2">Painel do Veículo</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Tipo de Anexo!
                            </Form.Control.Feedback>
                        </Col>
                        <Col className='col-2'>
                            <Form.Label style={{opacity: 0,}}>Anexar arquivo:</Form.Label><br></br>
                            <Button variant='primary' onClick={handleClickBtnUploadArquivo} disabled={isLoading} about='anexar_arquivo' style={{width: "100%"}}>Enviar</Button>
                        </Col>
                    </Row>
                    <br/>
                </div>
                
                <div id='div_anexos' style={{display: Object.values(props.custoDados).length > 0 ? 'block' : 'none'}}>
                    <h2 style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Anexos</h2>
                    <br></br>
                    <div style={{overflow: 'hidden'}}>
                        {
                            anexosForm.map((item, index) => (
                                <Card key={index} style={{width: "18rem", marginBottom: "10px", marginRight: "10px", float: 'left', height: "135px"}}>
                                    <CardBody>
                                        <CardTitle>{item.id_tipo_anexo.descricao} #{item.id_anexo}</CardTitle>
                                        <CardSubtitle style={{height: "40px", overflow: "clip"}}>{item.nome_original}</CardSubtitle>
                                        <CardLink href={item.link} target='_blank'>Visualizar</CardLink>
                                        <Button variant='danger' onClick={(event) => {handleClickBtnDeletarAnexo(event, item.id_anexo)}} disabled={isLoading} about='deletar_anexo' size='sm' style={{ marginLeft: "15px" }}>Deletar</Button>
                                    </CardBody>
                                </Card>
                            ))
                        }
                    </div>
                </div>

                </Form>

            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={props.onHide}>Fechar</Button> */}
                {/* <Button variant='success' onClick={handleSubmit} about='criar_registros' style={{ marginRight: "15px" }}>Salvar Registros</Button> */}
                {Object.values(props.custoDados).length > 0 &&
                <Button variant='danger' onClick={handleClickBtnDelete} disabled={isLoading} about='deletar_registros' style={{ marginRight: "15px" }}>Deletar</Button>}
            </Modal.Footer>
        </Modal>
    )
}