import { useEffect, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { iNfServicosEmpresasViewDto } from "../dto/nf_servicos"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"

const columnHelper = createColumnHelper<iNfServicosEmpresasViewDto>()

const defaultColumns = [
    columnHelper.accessor(row => `${row.empresa.nome}`, {
        header: 'Empresa',
        size: 10,
    }),
    columnHelper.accessor('servicos', {
        header: 'Serviços disponíveis',
    }),
]

const defaultOrder:any = []

function NfServicosEmpresasPage() {
    const [servicosEmpresas, setServicosEmpresas] = useState<iNfServicosEmpresasViewDto[]>([])

    useEffect(() => {
        document.title = "NF Serviços Empresas | OB PORTUS"

        listarDados()
    }, [])

    async function listarDados() {
        const api = await reqGetNew(ApiEndpoints.nfServicosEmpresasConsultar)

        if (api.status === 200) {
            const dadosAux:iNfServicosEmpresasViewDto[] = api.data
            setServicosEmpresas(dadosAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = servicosEmpresas[idx]
        const link = `/nf/servicos/empresa/editar?id=${reg.empresa.id_empresa}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = servicosEmpresas[idx]
        const link = `/nf/servicos/empresa/editar?id=${reg.empresa.id_empresa}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Serviços Empresas</h1>
            </div>
            <br></br>
            {<DataTable dados={servicosEmpresas} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default NfServicosEmpresasPage