import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import $ from 'jquery'
import 'jquery-mask-plugin'
import { iNfServicoDto } from "../dto/nf_servicos"
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput, disabledInput } from "../../../components/funcForInput"

function NfServicoEditarPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [servicoDados, setTipoDados] = useState<iNfServicoDto | any>({})

    useEffect(() => {
        $('.cod_serv').mask('00.00', {reverse: true});

        document.title = "Cadastrar NF Serviço | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("cod_serv")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                cod_serv: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.nfServicoConsultar, dados)

            if (api.status === 200) {
                const servico:iNfServicoDto = api.data
                setTipoDados(servico)
                defineValueInInput('cod_serv', servico.cod_serv)
                disabledInput('cod_serv', true)
                defineValueInInput('descricao', servico.descricao)
                defineValueInInput('iss', servico.iss_incidencia_municipio_prestador)
            }

            setLoading(false)
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar NF Serviço | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iNfServicoDto = {
            cod_serv: Object.values(servicoDados).length > 0 ? servicoDados?.cod_serv : form.cod_serv.value,
            descricao: form.descricao.value,
            iss_incidencia_municipio_prestador: form.iss.value,
        }
        
        if (dados.cod_serv === "") {
            form.cod_serv.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descricao === "") {
            form.descricao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(servicoDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(servicoDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iNfServicoDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.nfServicoCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Serviço cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/servicos`)
        }
        setLoading(false)

    }

    async function editar(dados: iNfServicoDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.nfServicoEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Serviço editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/servicos`)
        }

        setLoading(false)
    }

    async function handleExcluirTipo() {
        if (!window.confirm('Tem certeza que deseja excluir o Serviço?')) {
            return
        }

        const dados = {
            cod_serv: servicoDados.cod_serv
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.nfServicoDeletar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Serviço excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/servicos`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Serviço</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/nf/servicos`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formNfServico' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Código:</Form.Label>
                            <Form.Control name='cod_serv' type="text" id='cod_serv' className="cod_serv" placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Código!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>ISS Incidencia Municipio Prestador:</Form.Label>
                            <Form.Select name="iss" id="iss" aria-label="">
                                <option value="1">Retido</option>
                                <option value="0">Tributado</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o ISS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-12">
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Descrição!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(servicoDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(servicoDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(servicoDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluirTipo()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default NfServicoEditarPage