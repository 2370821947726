import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import { getUrlSearchParams } from "../../urlParams"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput } from "../../../components/funcForInput"
import { iDespesaFormaPagamentoDto } from "../dto/forma_pagamento"

function DespesasFormaPagamentoFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [formaPagamentoDados, setFormaPagamentoDados] = useState<iDespesaFormaPagamentoDto | any>({})

    useEffect(() => {
        document.title = "Cadastrar Forma de Pagamento | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                id_forma_pagamento: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.despesasFormaPagamentoConsultar, dados)

            if (api.status === 200) {
                const formaPagamento:iDespesaFormaPagamentoDto = api.data
                setFormaPagamentoDados(formaPagamento)
                defineValueInInput('codigo', formaPagamento.codigo)
                defineValueInInput('descricao', formaPagamento.descricao)
                defineValueInInput('status', formaPagamento.ativo)
                defineValueInInput('reembolsavel', formaPagamento.reembolsavel)
                defineValueInInput('cartao', formaPagamento.cartao)
                defineValueInInput('vencimento', formaPagamento.vencimento)
            }

            setLoading(false)
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar Forma de Pagamento | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iDespesaFormaPagamentoDto = {
            id_forma_pagamento: Object.values(formaPagamentoDados).length === 0 ? 0 : formaPagamentoDados.id_forma_pagamento,
            codigo: form.codigo.value,
            descricao: form.descricao.value,
            reembolsavel: Number(form.reembolsavel.value),
            cartao: Number(form.cartao.value),
            vencimento: Number(form.vencimento.value),
            ativo: Number(form.status.value)
        }
        
        if (dados.codigo === "") {
            form.codigo.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descricao === "") {
            form.descricao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(formaPagamentoDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(formaPagamentoDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iDespesaFormaPagamentoDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.despesasFormaPagamentoCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Forma Pagamento cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/formasPagamento`)
        }
        setLoading(false)

    }

    async function editar(dados: iDespesaFormaPagamentoDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.despesasFormaPagamentoEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Forma Pagamento editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/formasPagamento`)
        }

        setLoading(false)
    }

    async function handleExcluirTipo() {
        if (!window.confirm('Tem certeza que deseja excluir a Forma Pagamento?')) {
            return
        }

        const dados = {
            id_forma_pagamento: formaPagamentoDados.id_forma_pagamento
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.despesasFormaPagamentoDeletar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Forma Pagamento excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/despesas/formasPagamento`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">Despesas Forma de Pagamento</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/despesas/formasPagamento`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formDespesasFormasPagamento' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Descrição!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Código:</Form.Label>
                            <Form.Control name='codigo' type="text" id='codigo' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Código!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Select name="status" id="status" aria-label="">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Status!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Reembolsável:</Form.Label>
                            <Form.Select name="reembolsavel" id="reembolsavel" aria-label="">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Reembolsável!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Cartão:</Form.Label>
                            <Form.Select name="cartao" id="cartao" aria-label="">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Cartão!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Vencimento:</Form.Label>
                            <Form.Select name="vencimento" id="vencimento" aria-label="">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o Vencimento!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(formaPagamentoDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(formaPagamentoDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(formaPagamentoDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluirTipo()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default DespesasFormaPagamentoFormPage