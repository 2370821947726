import React from "react";
import iconDashboard from '../../img/home/dashboard.svg'
import iconColaboradores from '../../img/home/people.svg'
import iconPonto from '../../img/home/clock.svg'
import iconComunicados from '../../img/home/email.svg'
import iconSolicitacoes from '../../img/home/edit-alt.svg'
import iconQrcodes from '../../img/home/qrcode.svg'
import iconDespesas from '../../img/home/report.svg'
import iconDocumentos from '../../img/home/files.svg'
import iconPainel from '../../img/home/menu-burger.svg'
import '../../styles/home/menu.css'

function Menu() {

    const handleClickMenuItem = (e) => {
        if (e.target.className === 'menu-item-title' || e.target.className === 'menu-item-icon') {
            const status = e.target.parentElement.children[2].style.display

            if (status === 'block') {
                e.target.parentElement.children[2].style.display = 'none'
                e.target.parentElement.children[3].style.display = 'none'
            } else {
                e.target.parentElement.children[2].style.display = 'block'
                e.target.parentElement.children[3].style.display = 'block'
            }
        }

        if (e.target.className === 'menu-item-sub') {
            const status = e.target.children[2].style.display

            if (status === 'block') {
                e.target.children[2].style.display = 'none'
                e.target.children[3].style.display = 'none'
            } else {
                e.target.children[2].style.display = 'block'
                e.target.children[3].style.display = 'block'
            }
        } 
    }

    const mouseOverMenu = (e) => {
        document.getElementById("menu").style.width = "250px"

        document.querySelectorAll('.menu-item-title').forEach((tag) => {
            tag.style.display = "block"
        })
    }

    const mouseLeaveMenu = (e) => {
        document.getElementById("menu").style.width = "50px"

        document.querySelectorAll('.menu-item-title').forEach((tag) => {
            tag.style.display = "none"
        })

        document.querySelectorAll('.menu-item-sub-items').forEach((tag) => {
            tag.style.display = "none"
        })
    }

    return (
        <div id="menu" onMouseOver={mouseOverMenu} onMouseLeave={mouseLeaveMenu}>

            <a href="https://admin.obportus.net/dashboard" className="menu-sub-items-link"><div className='menu-item'>
                <img className='menu-item-icon' src={iconDashboard} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        marginLeft: '8px'
                    }}></img>
                <h1 className="menu-item-title">Dashboard</h1>
            </div></a>

            <a href="/colaboradores" className="menu-sub-items-link"><div className='menu-item'>
                <img className='menu-item-icon' src={iconColaboradores} alt='imagem_nao_encontrada' style={{
                    width: '34px',
                    marginLeft: '8px'
                }}></img>
                <h1 className="menu-item-title">Colaboradores</h1>
            </div></a>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconPonto} alt='imagem_nao_encontrada' style={{
                    width: '50px',
                    marginLeft: '0px'
                }}></img>
                <h1 className="menu-item-title">Ponto Digital</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="/ponto/consultar" className="menu-sub-items-link">- Consultar</a></p>
                    <p><a href="/ponto/marcacoes" className="menu-sub-items-link">- Marcações</a></p>
                    <p><a href="/ponto/ocorrencias" className="menu-sub-items-link">- Ocorrências</a></p>
                    <p><a href="/ponto/folha" className="menu-sub-items-link">- Folha de Ponto</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/ponto/escalas" className="menu-sub-items-link">- Escalas</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/ponto/escalas/tipo" className="menu-sub-items-link">- Tipo Escalas</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/ponto/calculos" className="menu-sub-items-link">- Cálculos</a></p>
                    {/* <p><a href="https://admin.obportus.net/dashboard/ponto/servico/ajustes" className="menu-sub-items-link">- Solicitação de Ajustes</a></p> */}
                    {/* <p><a href="https://admin.obportus.net/dashboard/ponto/servico/configuracoes" className="menu-sub-items-link">- Configurações</a></p> */}
                </div>
            </div>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconComunicados} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        margin: '8px 0px 8px 8px'
                    }}></img>
                <h1 className="menu-item-title">Comunicados</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="https://admin.obportus.net/dashboard/comunicados" className="menu-sub-items-link">- Cadastrar</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/relatorios" className="menu-sub-items-link">- Relatórios</a></p>
                </div>
            </div>

            <a href="https://admin.obportus.net/dashboard/contatos" className="menu-sub-items-link"><div className='menu-item'>
                <img className='menu-item-icon' src={iconSolicitacoes} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        marginLeft: '8px'
                    }}></img>
                <h1 className="menu-item-title">Solicitações</h1>
            </div></a>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconQrcodes} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        margin: '8px 0px 8px 8px'
                    }}></img>
                <h1 className="menu-item-title">QR Code</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="https://admin.obportus.net/dashboard/qrcodes" className="menu-sub-items-link">- Cadastrar</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/scanners" className="menu-sub-items-link">- Scanners</a></p>
                </div>
            </div>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconDespesas} alt='imagem_nao_encontrada' style={{
                        width: '25px',
                        margin: '8px 0px 8px 12px'
                    }}></img>
                <h1 className="menu-item-title">Gestão de Despesas</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="https://admin.obportus.net/dashboard/despesas/meus_relatorios" className="menu-sub-items-link">- Meus Relatórios</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/despesas/todos_relatorios" className="menu-sub-items-link">- Todos Relatórios</a></p>
                    <p><a href="/despesas/frota" className="menu-sub-items-link">- Controle Frota</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/despesas/relatorios" className="menu-sub-items-link">- Relatórios</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/despesas/financeiro" className="menu-sub-items-link">- Financeiro</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/despesas/relatorios/automaticos" className="menu-sub-items-link">- Relatórios Automáticos</a></p>
                    <p><a href="/despesas/clientes" className="menu-sub-items-link">- Clientes</a></p>
                    <p><a href="/despesas/formasPagamento" className="menu-sub-items-link">- Formas de Pagamento</a></p>
                    <p><a href="/despesas/cartoes" className="menu-sub-items-link">- Cartões</a></p>
                    <p><a href="/despesas/categorias" className="menu-sub-items-link">- Categorias</a></p>
                </div>
            </div>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconDocumentos} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        margin: '8px 0px 8px 8px'
                    }}></img>
                <h1 className="menu-item-title">Gestão de Documentos</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="https://admin.obportus.net/dashboard/documentos" className="menu-sub-items-link">- Documentos</a></p>
                    <p><a href="https://admin.obportus.net/dashboard/documentos/relatorios" className="menu-sub-items-link">- Relatórios</a></p>
                </div>
            </div>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconPainel} alt='imagem_nao_encontrada' style={{
                        width: '34px',
                        margin: '8px 0px 8px 8px'
                    }}></img>
                <h1 className="menu-item-title">Configurações</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="/painelControle/empresas" className="menu-sub-items-link">- Empresas</a></p>
                    <p><a href="/painelControle/centroCusto" className="menu-sub-items-link">- Centro de Custo</a></p>
                    <p><a href="/painelControle/localTrabalho" className="menu-sub-items-link">- Locais de Trabalho</a></p>
                    <p><a href="/painelControle/cargos" className="menu-sub-items-link">- Cargos</a></p>
                    <p><a href="/painelControle/situacoes" className="menu-sub-items-link">- Situações</a></p>
                    <p><a href="/painelControle/usuarios" className="menu-sub-items-link">- Usuários</a></p>
                    <p><a href="/painelControle/definicoesApp" className="menu-sub-items-link">- Definições</a></p>
                </div>
            </div>

            <div className='menu-item-sub' onClick={handleClickMenuItem}>
                <img className='menu-item-icon' src={iconDespesas} alt='imagem_nao_encontrada' style={{
                        width: '25px',
                        margin: '8px 0px 8px 12px'
                    }}></img>
                <h1 className="menu-item-title">Gestão de NFs</h1>
                <div className="menu-item-blank menu-item-sub-items"></div>
                <div className="menu-item-list menu-item-sub-items">
                    <p><a href="/nfs" className="menu-sub-items-link">- Emissão</a></p>
                    <p><a href="/nf/modelos" className="menu-sub-items-link">- Modelos</a></p>
                    <p><a href="/nf/empresas" className="menu-sub-items-link">- Empresas</a></p>
                    <p><a href="/nf/clientes" className="menu-sub-items-link">- Clientes</a></p>
                    <p><a href="/nf/clientes/tipos" className="menu-sub-items-link">- Clientes Tipos</a></p>
                    <p><a href="/nf/servicos" className="menu-sub-items-link">- Serviços</a></p>
                    <p><a href="/nf/servicos/empresas" className="menu-sub-items-link">- Serviços Empresas</a></p>
                    <p><a href="/nf/cidades" className="menu-sub-items-link">- Cidades</a></p>
                    <p><a href="/nf/config" className="menu-sub-items-link">- Configurações</a></p>
                </div>
            </div>

        </div>
    )

}

export default Menu
