import dayjs from 'dayjs'
import styles from '../styles/inicial.module.css'

interface iProps {
    empresas: any[]
    handleEmpresa: any
}

export default function NfSelecionarEmpresas({ empresas, handleEmpresa }:iProps) {
    return (
        <div className="div-row">
            {
                empresas.map(
                    (item, index) => (
                        <div key={index}>
                            <div className={styles.divEmpresaItem} onClick={(e) => { handleEmpresa(item.empresa) }}>
                                <img src={item.empresa.logo} alt="banner_empresa" width={80}></img>
                                <p>{item.empresa.nome}</p>
                            </div>
                            <div className={styles.divEmpresaItemInfo}>
                                <p>Notas pendentes: {item.qtd_pendentes}</p>
                                <br></br>
                                <p>Notas emitidas (mes atual): {item.qtd_emitidas}</p>
                                <p>Valor total (mes atual): {item.valor_total}</p>
                                <br></br>
                                <p>Notas emitidas (mes anterior): {item.qtd_emitidas_anterior}</p>
                                <p>Valor total (mes anterior): {item.valor_total_anterior}</p>
                                <br></br>
                                {
                                    (item.empresa.data_permissao_final !== null) &&
                                    <p style={{
                                        color: item.permissao_cor_alerta, fontWeight: item.permissao_dias_para_expirar > 31 ? 'normal' : 'bold',
                                    }}>Permissão expira em {item.permissao_dias_para_expirar} dias, {dayjs(item.empresa.data_permissao_final).format("DD/MM/YYYY")}.</p>
                                }
                                { item.empresa.data_permissao_prefeitura !== null && <p>Última permissão solicitada em: {dayjs(item.empresa.data_permissao_prefeitura).format("DD/MM/YYYY")}</p>}
                            </div>
                        </div>
                    )
                )
            }
            {
                empresas.length === 0 &&
                <p>Nenhum registro encontrado!</p>
            }
        </div>
    )
}