export function getValueFromInput(id:string):any {
    let ele :any = document.getElementById(id)
    return ele.value
}

export function defineValueInInput(id:string, value:any) {
    let ele :any = document.getElementById(id)
    ele.value = value
}

export function disabledInput(id:string, value:boolean) {
    let ele :any = document.getElementById(id)
    ele.disabled = value
}