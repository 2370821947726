import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import { ApiEndpoints, reqPostNew, reqGetNew } from '../../../config/apiClient'
import ModalCusto from './ModalCusto'
import dayjs from 'dayjs'
import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../../components/dataTable/DataTable'
import $ from 'jquery'
import 'jquery-mask-plugin'
import LoadingModal from '../../../components/loadingModal'
import { getUrlSearchParams } from '../../urlParams'

const customSelectStyles = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '256px',
        borderColor: "#dee2e6",
        minHeight: '40px',
        maxHeight: '120px',
        marginRight: '5px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        minHeight: '40px',
        maxHeight: '120px',
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        height: '40px',
    }),
}

interface iCusto {
    "id_custo": number,
    "status": string,
    "data_hora_custo": string,
    "tipo_custo": string,
    "veiculo": string,
    "cliente": string,
    "valor_total": string,
    "usuario": string,
    "itens": string,
    "hodometro": number
}

const columnHelper = createColumnHelper<iCusto>()

const defaultColumns = [
    columnHelper.accessor(row => dayjs(row.data_hora_custo).format("DD/MM/YYYY - HH:mm"), {
        header: 'Data',
        size: 120,
        minSize: 120,
        maxSize: 120
    }),
    columnHelper.accessor('status', {
        header: 'Status',
    }),
    columnHelper.accessor(row => `${row.tipo_custo} #${row.id_custo}`, {
        header: 'Tipo',
        size: 160,
        minSize: 160
    }),
    columnHelper.accessor('cliente', {
        header: 'Cliente',
        size: 200,
        minSize: 200
    }),
    columnHelper.accessor('valor_total', {
        header: 'Valor',
        size: 100,
        minSize: 100,
        maxSize: 150
    }),
    columnHelper.accessor('veiculo', {
        header: 'Veículo',
        size: 200,
        minSize: 200
    }),
    columnHelper.accessor('itens', {
        header: 'Itens',
        size: 200,
        minSize: 150
    }),
    columnHelper.accessor('usuario', {
        header: 'Usuário',
        size: 180,
        minSize: 180,
        maxSize: 180
    }),
]

const defaultOrder:any = []

export default function FrotaDespesasPage() {

    $('.inputMoney').mask('#.##0,00', {
        reverse: true,
        placeholder: '0,00'
    })

    $('#competencia').mask('00/0000', {
        reverse: false
    })

    function defineValueInInput(id:string, value:any) {
        let ele :any = document.getElementById(id)
        if (ele === null) {
            return
        }
        ele.value = value
    }

    function formatarStringInReal(valor:string):string {
        let num = Number(valor).toFixed(2)
        defineValueInInput("valor_aux_view_custos", num)
        return "R$ " + $("#valor_aux_view_custos").masked(num)
    }

    const [competenciaFiltro, setCompetenciaFiltro] = useState({
        "mes": 0,
        "ano": 0
    })

    const [loading, setLoading] = useState<boolean>(false)

    const [custosDados, setCustosDados] = useState<iCusto[]>([])
    const [custosDadosCompletos, setCustosDadosCompletos] = useState<any[]>([])
    const [custoS, setCustoS] = useState({})

    const [statusFiltro, setStatusFiltro] = useState<any>({})
    const [statusSelecionados, setStatusSelecionados] = useState<any>([])

    const [veiculosFiltro, setVeiculosFiltros] = useState<any>({})
    const [veiculosSelecionados, setVeiculosSelecionados] = useState<any>([])

    const [tiposCustosFiltro, setTiposCustosFiltro] = useState<any>({})
    const [tiposCustosSelecionados, setTiposCustosSelecionados] = useState<any>([])

    const [tiposManutencaoFiltro, setTiposManutencaoFiltro] = useState<any>({})
    const [tiposManutencaoSelecionados, setTiposManutencaoSelecionados] = useState<any>([])

    const [clientesFiltro, setClientesFiltro] = useState<any>({})
    const [modalCustoShow, setModalCustoShow] = useState<boolean>(false);

    useEffect(() => {

        document.title = `Despesas Frota | OB PORTUS`

        let competencia_filtro:any = document.getElementById("competencia")
        competencia_filtro.value = dayjs().format("MM/YYYY")

        const getFiltrosDados = async () => {

            setLoading(true)

            const api = await reqGetNew(ApiEndpoints.frotaFiltrosDados)

            if (api.status === 200) {

                let statusFiltroAux = api.data.status.map((item:any, index:any) => (
                    {
                        "value": `${item.id_status}`,
                        "label": `${item.descricao}`
                    }
                ))
                setStatusFiltro(statusFiltroAux)

                // setVeiculosDados(api.data.veiculos)
                let veiculosFiltroAux = api.data.veiculos.map((item:any, index:any) => (
                    {
                        "value": `${item.id_veiculo}`,
                        "label": `${item.veiculo} - ${item.placa}`
                    }
                ))
                setVeiculosFiltros(veiculosFiltroAux)

                // setTiposCustosDados(api.data.tipos_custos)
                let tiposCustosDadosAux = api.data.tipos_despesas.map((item:any, index:any) => (
                    {
                        "value": `${item.id_tipo_despesa}`,
                        "label": `${item.tipo_despesa}`
                    }
                ))
                setTiposCustosFiltro(tiposCustosDadosAux)

                // setTiposManutencaoDados(api.data.tipos_manutencoes)
                let tiposManutencoesDadosAux = api.data.tipos_manutencao.map((item:any, index:any) => (
                    {
                        "value": `${item.id_tipo_manutencao}`,
                        "label": `${item.tipo_manutencao}`
                    }
                ))
                setTiposManutencaoFiltro(tiposManutencoesDadosAux)

                let clientesDadosAux = api.data.clientes.map((item:any, index:any) => (
                    {
                        "value": `${item.id_cliente}`,
                        "label": `(${item.id_centro_custo}) ${item.descricao}`
                    }
                ))
                setClientesFiltro(clientesDadosAux)

                await getLista()

                // await funcSleep(2500)
                let urlParams:URLSearchParams = getUrlSearchParams()
                const id_custo = urlParams.get("id")
                if (id_custo !== null) {
                    const apiCustoRegistro = await reqPostNew(ApiEndpoints.frotaListarRegistro, {
                        id_custo: Number(id_custo)
                    })
                    if (apiCustoRegistro.status === 200) {
                        await getCustoDadosRegistro({id_custo: Number(id_custo)}, apiCustoRegistro.data)
                    }
                }

            }

            setLoading(false)

        }

        getFiltrosDados()

        // eslint-disable-next-line
    }, [])

    function definirDescricaoColunaItens(itens:any[], anexos:any[]):string {
        let item = ''
        for (let reg of itens) {
            if (reg.id_categoria === 1) {
                if (item === '') {
                    item = reg.id_tipo_despesa.tipo_despesa
                } else {
                    item = `${item}/${reg.id_tipo_despesa.tipo_despesa}`
                }
                continue
            }
            if (reg.id_categoria === 2) {
                if (item === '') {
                    item = reg.id_tipo_manutencao.tipo_manutencao
                } else {
                    item = `${item}/${reg.id_tipo_manutencao.tipo_manutencao}`
                }
                continue
            }
        }
        if (anexos.length > 0) {
            if (item === "") {
                item = `Anexos(${anexos.length})`
            } else {
                item = `${item}/Anexos(${anexos.length})`
            }
        }
        return item
    }

    async function handleClickFiltrar(event:any) {
        await getLista()
    }

    function normalizeIdsSelecionados(arrayData:any[]):number[] {
        let ids:number[] = []
        for (let reg of arrayData) {
            ids.push(Number(reg.value))
        }
        return ids
    }

    async function getLista() {

        setLoading(true)

        await loadLista()

        setLoading(false)

    }

    async function loadLista() {
        let competenciaFiltro = {
            "mes": 0,
            "ano": 0
        }

        let dados = {
            "competencia": "null",
            "id_status": normalizeIdsSelecionados(statusSelecionados),
            "id_veiculo": normalizeIdsSelecionados(veiculosSelecionados),
            "id_tipo_despesa": normalizeIdsSelecionados(tiposCustosSelecionados),
            "id_tipo_manutencao": normalizeIdsSelecionados(tiposManutencaoSelecionados)
        }
        console.log(dados)

        let competencia_filtro:any = document.getElementById("competencia")
        if (competencia_filtro.value !== "") {
            const competencia = String(competencia_filtro.value).split("/")
            competenciaFiltro = {
                "mes": Number(competencia[0]),
                "ano": Number(competencia[1])
            }
            dados.competencia = `${competencia[1]}-${competencia[0]}`
        }

        setCompetenciaFiltro(competenciaFiltro)
        
        const api = await reqPostNew(ApiEndpoints.frotaListar, dados)

        if (api.status === 200) {
            // console.log(api.data)

            let custos:any[] = api.data
            setCustosDadosCompletos(custos)
            let custos_list:iCusto[] = []

            for (let i in custos) {

                let reg = custos[i]

                let item:iCusto = {
                    id_custo: reg.id_custo,
                    status: reg.id_status.descricao,
                    data_hora_custo: reg.data_hora_custo,
                    tipo_custo: reg.id_tipo_custo.tipo_custo,
                    veiculo: `${reg.id_veiculo.veiculo} | ${reg.id_veiculo.placa}`,
                    cliente: reg.id_cliente.descricao,
                    valor_total: formatarStringInReal(reg.valor_total),
                    usuario: reg.id_usuario === null ? reg.id_colaborador.nome_completo : reg.id_usuario.nome,
                    itens: definirDescricaoColunaItens(reg.custos, reg.anexos),
                    hodometro: reg.hodometro
                }
                custos_list.push(item)

            }

            setCustosDados(custos_list)

        }
    }

    async function getCustoDados(dados:any) {

        console.log("SELECIONAR")
        const custosEncontrado = custosDadosCompletos.filter(reg => reg.id_custo === dados.id_custo)
        if (custosEncontrado.length === 0) {
            return
        }
        const custoEncontrado = custosEncontrado[0]
        console.log(custoEncontrado)
        setCustoS(custoEncontrado)
        window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA CONTROLE DE FROTA", `/despesas/frota?id=${custoEncontrado.id_custo}`)

        // const api = await reqPost(ApiEndpoints.frotaCusto, dados)
        // if (api.status === 200) {
        //     console.log(api.data)
        //     setCustoS(api.data)
        // }

    }

    async function getCustoDadosRegistro(dados:any, custoEncontrado:any) {
        setModalCustoShow(true)
        console.log("SELECIONAR REGISTRO URL")
        console.log(custoEncontrado)
        setCustoS(custoEncontrado)
        window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA CONTROLE DE FROTA", `/despesas/frota?id=${custoEncontrado.id_custo}`)
    }

    function tableMiddleClickhandler(event:any) {
        // const idx = Number(event.target.parentElement.getAttribute('about'))
        // const urlParams = getUrlSearchParams()
        // const id_empresa = urlParams.get("id_empresa")

        // if (event.button === 1) {
        //     window.open(`/nf?id_empresa=${id_empresa}&id_nota=${nfsDados[idx].id_nota}`)
        // }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))

        setModalCustoShow(true)
        getCustoDados(custosDados[idx])
    }

    function handlePesquisaCompetencia(event:any) {

        let competencia = String(event.target.value)

        if (competencia.length === 7) {

            const arrayComp = competencia.split("/")

            const dados = {
                "mes": Number(arrayComp[0]),
                "ano": Number(arrayComp[1])
            }

            if (dados.mes < 0 || dados.mes >= 13) {
                return
            }

            if (dados.ano < 2020) {
                return
            }

            if (dados.mes === competenciaFiltro.mes && dados.ano === competenciaFiltro.ano) {
                return
            }

            console.log(dados)
            getLista()
            return

        }

        if (competencia === "") {
            const dados = {
                "mes": 0,
                "ano": 0
            }
            console.log(dados)
            getLista()
            return
        }

    }

    function handleCloseModalCusto() {
        setModalCustoShow(false)
        setCustoS({})
        window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA CONTROLE DE FROTA", `/despesas/frota`)
    }

    return (
        <div id='main'>
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <input type='text' name='valor_aux_view_custos' id='valor_aux_view_custos' className='inputMoney' style={{display:'none'}} />
            <ModalCusto show={modalCustoShow} custoDados={custoS} onHide={handleCloseModalCusto} veiculosFiltro={veiculosFiltro} tiposCustosFiltro={tiposCustosFiltro} tiposManutencaoFiltro={tiposManutencaoFiltro} clientesFiltro={clientesFiltro} onUpdate={loadLista} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    
                }}>
                    <h1 id='titleMain'>Despesas Frota</h1>
                    <Button variant='secondary' style={{height: '40px', display: 'flex', alignItems: 'center', margin: '0px 15px 0px 15px'}} onClick={() => {
                        setModalCustoShow(true)
                    }} ><span>Criar</span> <span className="material-symbols-outlined">add</span></Button>
                    <Form.Control type="text" id='competencia' onKeyUp={handlePesquisaCompetencia} placeholder="Competência" style={{width: "150px", marginRight: "10px"}}/>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}>
                    <Select name='ids_status' id='ids_status' options={statusFiltro} placeholder="Status" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            setStatusSelecionados(choice)
                        }
                    } styles={customSelectStyles} />
                    <Select name='ids_veiculos' id='ids_veiculos' options={veiculosFiltro} placeholder="Veículos" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            setVeiculosSelecionados(choice)
                        }
                    } styles={customSelectStyles} />
                    <Select name='ids_tipos_custos' id='ids_tipos_custos' options={tiposCustosFiltro} placeholder="Tipos Custos" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            setTiposCustosSelecionados(choice)
                        }
                    } styles={customSelectStyles} />
                    <Select name='ids_tipos_manutencoes' id='ids_tipos_manutencoes' options={tiposManutencaoFiltro} placeholder="Tipos Manutenções" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            setTiposManutencaoSelecionados(choice)
                        }
                    } styles={customSelectStyles} />
                    <Button variant='primary' style={{height: '40px'}} onClick={handleClickFiltrar}><span className="material-symbols-outlined">filter_alt</span></Button>
                </div>
            </div>
            <br></br>
            <DataTable dados={custosDados} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />
        </div>
    )
}