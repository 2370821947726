import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"
import { iNfCidadeDto } from "../dto/nf_cidades"

const columnHelper = createColumnHelper<iNfCidadeDto>()

const defaultColumns = [
    columnHelper.accessor('cod_cidade', {
        header: 'Cod. Cidade',
        size: 10,
    }),
    columnHelper.accessor('nome', {
        header: 'Cidade',
        size: 300,
    }),
    columnHelper.accessor('uf', {
        header: 'Estado',
        size: 30,
    }),
    columnHelper.accessor('cep', {
        header: 'CEP',
    }),
    columnHelper.accessor('iss', {
        header: 'Valor ISS',
    }),
]

const defaultOrder:any = []

function NfCidadesPage() {
    const [cidades, setCidades] = useState<iNfCidadeDto[]>([])

    useEffect(() => {
        document.title = "NF Cidades | OB PORTUS"

        listarDados()
    }, [])

    async function listarDados() {
        const api = await reqGetNew(ApiEndpoints.nfCidadesListar)

        if (api.status === 200) {
            const dadosAux:iNfCidadeDto[] = api.data
            setCidades(dadosAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = cidades[idx]
        const link = `/nf/cidade/editar?cod=${reg.cod_cidade}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = cidades[idx]
        const link = `/nf/cidade/editar?cod=${reg.cod_cidade}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Cidades</h1>
                <div className="div-row">
                    <Button onClick={() => {
                        window.location.assign(`/nf/cidade/cadastrar`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={cidades} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default NfCidadesPage