import axios, { AxiosError, AxiosResponse } from 'axios'
import { getCookie } from './cookies'
import { toast } from 'react-toastify'

interface LinkEndpoint {
    endpoint: string,
    timeout: number
}

interface CrudLinksEndpoint {
    cadastrar: LinkEndpoint,
    todos: LinkEndpoint,
    consultar: LinkEndpoint,
    editar: LinkEndpoint,
} 

interface ReqResponse {
    status: number,
    data: any
}

// const baseUrl:string = 'https://admin.obportus.net'
const baseUrl:string = process.env.REACT_APP_ENV_CONFIG === 'dev' ? 'http://localhost:3000' : 'https://admin.obportus.net'

// const baseUrlNew:string = 'https://api.obportus.net'
const baseUrlNew:string = process.env.REACT_APP_ENV_CONFIG === 'dev' ? 'http://localhost:3002' : 'https://api.obportus.net'

export class ApiEndpoints {

    static apiOld:string = baseUrl
    static apiNew:string = baseUrlNew

    static baseUrl:string = baseUrl

    static login:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/usuario/login",
        // endpoint: baseUrl + "/auth/site",
        timeout: 10000
    }

    static logoff:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/usuario/logoff",
        timeout: 10000
    }

    static colaboradores:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/colaboradores",
        timeout: 10000
    }

    static colaborador:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/colaborador",
        timeout: 10000
    }

    static dadosFuncionario:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/colaborador/funcionario/dados",
        timeout: 10000
    }

    static colaboradorEditBasic:LinkEndpoint = {
        endpoint: baseUrl + "/api/colaborador/edit/basic",
        timeout: 10000
    }

    static colaboradorEditAccess:LinkEndpoint = {
        endpoint: baseUrl + "/api/colaborador/edit/access",
        timeout: 10000
    }

    static colaboradorCadastrar:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/colaborador/cadastrar",
        timeout: 10000
    }

    static colaboradorEditarBasic:LinkEndpoint = {
        endpoint: baseUrlNew + "/colaboradores/editar/basic",
        timeout: 10000
    }

    static colaboradorEscalas:LinkEndpoint = {
        endpoint: baseUrlNew + "/escalas/consultar/todas",
        timeout: 10000
    }

    static colaboradorEscalasHistorico:LinkEndpoint = {
        endpoint: baseUrlNew + "/escalas/consultar/escalas_historico/colaborador",
        timeout: 10000
    }

    static colaboradorEscalaAlterar:LinkEndpoint = {
        endpoint: baseUrlNew + "/colaboradores/editar/escala",
        timeout: 10000
    }

    static colaboradorEscalaHistoricoAlterar:LinkEndpoint = {
        endpoint: baseUrlNew + "/colaboradores/editar/escala/historico",
        timeout: 10000
    }

    static nfSelecionarEmpresas:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/selecionarEmpresas",
        timeout: 10000
    }

    static nfConsultarEmpresa:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/nfsEmpresa",
        timeout: 10000
    }

    static nfFormData:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/formData",
        timeout: 10000
    }

    static nfCadastro:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/cadastrar",
        timeout: 10000
    }

    static nfEditar:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/editar",
        timeout: 10000
    }

    static nfEnviarAh:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/enviarAh",
        timeout: 10000
    }

    static nfConsultar:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/consultar",
        timeout: 10000
    }

    static nfXml:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/xml",
        timeout: 10000
    }

    static nfXmlDownload:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/xmlDownload",
        timeout: 10000
    }

    static nfPdf:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/pdf",
        timeout: 10000
    }

    static nfCancelar:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/cancelar",
        timeout: 10000
    }
    
    static nfDeletar:LinkEndpoint = {
        endpoint: baseUrl + "/api/nf/deletar",
        timeout: 10000
    }

    static nfFiltros:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/filtros",
        timeout: 10000
    }

    static nfsCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/cadastrar",
        timeout: 10000
    }

    static nfsEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/editar",
        timeout: 10000
    }

    static nfsEmissao:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/emitir",
        timeout: 10000
    }

    static nfsCancelar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/cancelar",
        timeout: 10000
    }

    static nfsDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/deletar",
        timeout: 10000
    }

    static nfsDownloadPDF:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/baixar/pdf",
        timeout: 10000
    }

    static nfsDownloadXML:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/baixar/xml",
        timeout: 10000
    }

    static nfsDownloadRelatorioExcel:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/baixar/relatorio",
        timeout: 10000
    }

    static nfsEmpresa:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nfs/empresa",
        timeout: 10000
    }

    static nfsNota:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota",
        timeout: 10000
    }

    static nfEmpresas:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/empresas",
        timeout: 10000
    }

    static nfEmpresasInfos:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/empresas/infos",
        timeout: 10000
    }

    static nfEmpresasCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/empresas/cadastrar",
        timeout: 10000
    }

    static nfEmpresasEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/empresas/editar",
        timeout: 10000
    }

    static nfEmpresasConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/empresas/consultar",
        timeout: 10000
    }

    static nfClientes:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/clientes",
        timeout: 10000
    }

    static nfClientesCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/clientes/cadastrar",
        timeout: 10000
    }

    static nfClientesEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/clientes/editar",
        timeout: 10000
    }

    static nfClientesExcluir:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/clientes/excluir",
        timeout: 10000
    }

    static nfClientesConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/clientes/consultar",
        timeout: 10000
    }
    
    static nfTiposClientesConsultarTodos:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/tipos/clientes/todos",
        timeout: 10000
    }

    static nfTiposClientesConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/tipos/clientes/consultar",
        timeout: 10000
    }

    static nfTiposClientesCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/tipos/clientes/cadastrar",
        timeout: 10000
    }

    static nfTiposClientesEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/tipos/clientes/editar",
        timeout: 10000
    }

    static nfTiposClientesExcluir:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/tipos/clientes/excluir",
        timeout: 10000
    }

    static nfModeloCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/modelo/cadastrar",
        timeout: 10000
    }

    static nfModeloConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/modelo",
        timeout: 10000
    }

    static nfModelosConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/modelos",
        timeout: 10000
    }

    static nfModeloEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/modelo/editar",
        timeout: 10000
    }

    static nfModeloDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/modelo/deletar",
        timeout: 10000
    }

    static nfImportarPlanilha:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/nota/planilha/importar",
        timeout: 10000
    }

    static nfImportarPlanilhaClientes:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/importar/clientes",
        timeout: 10000
    }

    static nfImportarPlanilhaClientesCancelar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/importar/clientes/cancelar",
        timeout: 10000
    }

    static nfServicosConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servicos/consultar",
        timeout: 10000
    }

    static nfServicoConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servico/consultar",
        timeout: 10000
    }

    static nfServicoCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servico/cadastrar",
        timeout: 10000
    }

    static nfServicoEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servico/editar",
        timeout: 10000
    }

    static nfServicoDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servico/deletar",
        timeout: 10000
    }

    static nfServicosEmpresasConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servicos/empresas/consultar",
        timeout: 10000
    }

    static nfServicosEmpresaConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servicos/empresa/consultar",
        timeout: 10000
    }

    static nfServicosEmpresaCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/servicos/empresa/cadastrar",
        timeout: 10000
    }

    static nfCidadesListar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/cidades/consultar",
        timeout: 10000
    }

    static nfCidadeConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/cidade/consultar",
        timeout: 10000
    }

    static nfCidadeCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/cidade/cadastrar",
        timeout: 10000
    }

    static nfCidadeEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/cidade/editar",
        timeout: 10000
    }

    static nfCidadeDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/cidade/deletar",
        timeout: 10000
    }

    static nfConfigConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/config/consultar",
        timeout: 10000
    }

    static nfConfigEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/nf/config/editar",
        timeout: 10000
    }

    static despesasClientes:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/clientes",
        timeout: 10000
    }

    static despesasClienteConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cliente/consultar",
        timeout: 10000
    }

    static despesasClienteCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cliente/cadastrar",
        timeout: 10000
    }

    static despesasClienteEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cliente/editar",
        timeout: 10000
    }

    static despesasClienteDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cliente/deletar",
        timeout: 10000
    }

    static despesasFormasPagamento:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/formasPagamento",
        timeout: 10000
    }

    static despesasFormaPagamentoConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/formaPagamento/consultar",
        timeout: 10000
    }

    static despesasFormaPagamentoCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/formaPagamento/cadastrar",
        timeout: 10000
    }

    static despesasFormaPagamentoEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/formaPagamento/editar",
        timeout: 10000
    }

    static despesasFormaPagamentoDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/formaPagamento/deletar",
        timeout: 10000
    }

    static despesasCartoes:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartoes",
        timeout: 10000
    }

    static despesasCartaoConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartao/consultar",
        timeout: 10000
    }

    static despesasCartaoDadosConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartao/dados",
        timeout: 10000
    }

    static despesasCartaoCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartao/cadastrar",
        timeout: 10000
    }

    static despesasCartaoEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartao/editar",
        timeout: 10000
    }

    static despesasCartaoDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/cartao/deletar",
        timeout: 10000
    }

    static despesasCategorias:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/categorias",
        timeout: 10000
    }

    static despesasCategoriaConsultar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/categoria/consultar",
        timeout: 10000
    }

    static despesasCategoriaCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/categoria/cadastrar",
        timeout: 10000
    }

    static despesasCategoriaEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/categoria/editar",
        timeout: 10000
    }

    static despesasCategoriaDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/despesas/categoria/deletar",
        timeout: 10000
    }

    static frotaFiltrosDados:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/filtros",
        timeout: 10000
    }
    // static frotaFiltros:LinkEndpoint = {
    //     endpoint: baseUrl + "/api/painel/despesa/frota/filtros",
    //     timeout: 10000
    // }

    static frotaListar:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/listar",
        timeout: 10000
    }
    // static frotaListar:LinkEndpoint = {
    //     endpoint: baseUrl + "/api/painel/despesa/frota/listar",
    //     timeout: 10000
    // }

    static frotaListarRegistro:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/listar/registro",
        timeout: 10000
    }

    static frotaCadastro:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/cadastrar",
        timeout: 10000
    }
    // static frotaCadastro:LinkEndpoint = {
    //     endpoint: baseUrl + "/api/painel/despesa/frota/cadastro",
    //     timeout: 10000
    // }

    static frotaCusto:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/despesa/frota/custo",
        timeout: 10000
    }

    static frotaEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/editar",
        timeout: 10000
    }
    // static frotaEditar:LinkEndpoint = {
    //     endpoint: baseUrl + "/api/painel/despesa/frota/editar",
    //     timeout: 10000
    // }

    static frotaUploadAnexo:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/upload/anexo",
        timeout: 10000
    }

    static frotaDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/deletar",
        timeout: 10000
    }
    // static frotaDeletar:LinkEndpoint = {
    //     endpoint: baseUrl + "/api/painel/despesa/frota/deletar",
    //     timeout: 10000
    // }

    static frotaDeletarAnexo:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/deletar/anexo",
        timeout: 10000
    }

    static frotaRevisar:LinkEndpoint = {
        endpoint: baseUrlNew + "/frota/revisar",
        timeout: 10000
    }

    static pontoMarcacoes:LinkEndpoint = {
        endpoint: baseUrlNew + "/marcacoes/consultar",
        timeout: 10000
    }

    static pontoOcorrencias:LinkEndpoint = {
        endpoint: baseUrlNew + "/ocorrencias/todos",
        timeout: 10000
    }

    static apuracaoFolhaPonto:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/relatorios/folhaDePonto",
        timeout: 10000
    }

    static apuracaoFolhaPontoArquivo:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/relatorios/folhaDePonto/colaborador",
        timeout: 10000
    }

    static apuracaoFolhaPontoCompactar:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/relatorios/folhaDePonto/compactar",
        timeout: 10000
    }

    static apuracaoFolhaPontoColaboradores:LinkEndpoint = {
        endpoint: baseUrlNew + "/colaboradores/consultar/folha_ponto",
        timeout: 10000
    }

    static apuracaoConsultarFiltroDados:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/consultar/filtro/dados",
        timeout: 59000
    }

    static apuracaoConsultarDadosApuracao:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/consultar",
        timeout: 59000
    }

    static apuracaoCalcularDados:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular",
        timeout: 59000
    }

    static apuracaoCalcularDadosDownload:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular/download",
        timeout: 59000
    }

    static apuracaoCalcularDadosTotaisDownload:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular/download/totais",
        timeout: 59000
    }

    static apuracaoCalcularDadosDownloadPath:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular/download/path",
        timeout: 59000
    }

    static apuracaoCalcularDadosDownloadVarios:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular/download/varios",
        timeout: 59000
    }

    static apuracaoCalcularConsultarDados:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/calcular/consultar",
        timeout: 59000
    }

    static apuracaoOcorrenciaCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/ocorrencia/cadastrar",
        timeout: 10000
    }

    static apuracaoOcorrenciaCancelar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/ocorrencia/cancelar",
        timeout: 10000
    }
    
    static apuracaoOcorrenciaCancelarTodas:LinkEndpoint = {
        endpoint: baseUrlNew + "/ocorrencias/cancelar/periodo",
        timeout: 10000
    }

    static apuracaoAjusteCadastrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/solicitacoes/cadastrar",
        timeout: 59000
    }

    static apuracaoAjusteEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/solicitacoes/editar",
        timeout: 59000
    }

    static apuracaoAjusteDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/solicitacoes/excluir",
        timeout: 10000
    }

    static apuracaoObservacaoRegistrar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/observacao/registrar",
        timeout: 10000
    }

    static apuracaoObservacaoDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/observacao/deletar",
        timeout: 10000
    }

    static apuracaoMarcacoesAtualizar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/marcacoes/atualizar",
        timeout: 10000
    }

    static apuracaoTrocaTurnoEditar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/troca_turno/editar",
        timeout: 10000
    }

    static apuracaoReferenciaSalvar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/referencia/salvar",
        timeout: 10000
    }

    static apuracaoReferenciaDeletar:LinkEndpoint = {
        endpoint: baseUrlNew + "/apuracao/referencia/deletar",
        timeout: 10000
    }

    static apuracaoDownload:LinkEndpoint = {
        endpoint: baseUrl + "/api/painel/relatorios/folhaDePonto",
        timeout: 59000
    }

    static painelControle = {

        empresas: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/empresa/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/empresas/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/empresa/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/empresa/editar",
                timeout: 10000
            },
        } as CrudLinksEndpoint,

        cargos: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/cargo/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/cargos/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/cargo/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/cargo/editar",
                timeout: 10000
            }
        } as CrudLinksEndpoint,

        centros: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/centroCusto/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/centroCustos/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/centroCusto/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/centroCusto/editar",
                timeout: 10000
            },
        } as CrudLinksEndpoint,

        locais: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/localTrabalho/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/locaisTrabalho/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/localTrabalho/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/localTrabalho/editar",
                timeout: 10000
            },
        } as CrudLinksEndpoint,

        usuarios: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/usuario/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/usuarios/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/usuario/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/usuario/editar",
                timeout: 10000
            },
        } as CrudLinksEndpoint,

        definicoesApp: {
            dados: {
                endpoint: baseUrlNew + "/painelControle/definicoesAppDados",
                timeout: 10000
            } as LinkEndpoint,
            editar: {
                endpoint: baseUrlNew + "/painelControle/definicoesAppDados/editar",
                timeout: 10000
            } as LinkEndpoint,
        },

        situacoes: {
            cadastrar: {
                endpoint: baseUrlNew + "/painelControle/situacao/cadastrar",
                timeout: 10000
            },
            todos: {
                endpoint: baseUrlNew + "/painelControle/situacoes/consultar",
                timeout: 10000
            },
            consultar: {
                endpoint: baseUrlNew + "/painelControle/situacao/consultar",
                timeout: 10000
            },
            editar: {
                endpoint: baseUrlNew + "/painelControle/situacao/editar",
                timeout: 10000
            },
        } as CrudLinksEndpoint,

    }

}

export async function reqPostNoAuth(url: LinkEndpoint, data:any): Promise<ReqResponse> {

    const link:string = url.endpoint

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.post(link, data, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json"
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        if (apiError!.response?.status === 401) {
            const response:ReqResponse = {
                data: null,
                status: apiError!.response.status
            }
        
            return response
        }

        console.log(apiError!)
        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response



}

export async function reqGet(url: LinkEndpoint): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.get(link, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        console.log(apiError!)
        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqPost(url: LinkEndpoint, data:any): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.post(link, data, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        console.log(apiError!)

        if (apiError!.response?.status === 400) {
            let msg:any = apiError!.response?.data
            return {
                data: msg,
                status: 400
            }
        }
        
        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqGetNew(url: LinkEndpoint): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.get(link, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        console.log(apiError!)
        if (apiError!.code === "ERR_NETWORK") {
            toast.warning("Sem conexão!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (apiError!.code === "ECONNABORTED") {
            toast.warning("Sem conexão/timeout!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (apiError!.response !== undefined) {
            const status = apiError!.response.status

            if (status === 404 || status === 400) {
                const data:any = apiError!.response.data
                const messageData:string | string[] = data.message
                let msg:string = ""
                
                if (typeof(messageData) === "string") {
                    msg = messageData
                }

                if (typeof(messageData) === "object") {
                    msg = messageData[0]
                }

                toast.warning(msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }

            if (status === 500) {
                toast.warning("Erro na API, tente novamente mais tarde ou verifique com o suporte!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqPostNew(url: LinkEndpoint, data:any): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.post(link, data, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        if (apiError!.code === "ERR_NETWORK") {
            toast.warning("Sem conexão!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (apiError!.code === "ECONNABORTED") {
            toast.warning("Sem conexão/timeout!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        console.log(apiError!)

        if (apiError!.response !== undefined) {
            const status = apiError!.response.status

            if (status === 404 || status === 400) {
                const data:any = apiError!.response.data
                const messageData:string | string[] = data.message
                let msg:string = ""
                
                if (typeof(messageData) === "string") {
                    msg = messageData
                }

                if (typeof(messageData) === "object") {
                    msg = messageData[0]
                }

                toast.warning(msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
            
            if (status === 500) {
                toast.warning("Erro na API, tente novamente mais tarde ou verifique com o suporte!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqPatch(url: LinkEndpoint, data:any): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.patch(link, data, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        if (apiError!.code === "ERR_NETWORK") {
            toast.warning("Sem conexão!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (apiError!.code === "ECONNABORTED") {
            toast.warning("Sem conexão/timeout!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        console.log(apiError!)

        if (apiError!.response !== undefined) {
            const status = apiError!.response.status

            if (status === 404 || status === 400) {
                const data:any = apiError!.response.data
                const messageData:string | string[] = data.message
                let msg:string = ""
                
                if (typeof(messageData) === "string") {
                    msg = messageData
                }

                if (typeof(messageData) === "object") {
                    msg = messageData[0]
                }

                toast.warning(msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }

            if (status === 500) {
                toast.warning("Erro na API, tente novamente mais tarde ou verifique com o suporte!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqPut(url: LinkEndpoint, data:any): Promise<ReqResponse> {

    const link:string = url.endpoint
    const token:string = getCookie('token')

    let apiResponse:AxiosResponse
    let apiError:AxiosError
    let apiErrorCheck:Boolean = false

    await axios.put(link, data, {
        timeout: url.timeout,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(function (response:AxiosResponse) {
        apiResponse = response
        return response
    }).catch(function (error:AxiosError) {
        apiError = error
        apiErrorCheck = true
        return error
    })

    if (apiErrorCheck === true) {
        if (apiError!.code === "ERR_NETWORK") {
            toast.warning("Sem conexão!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (apiError!.code === "ECONNABORTED") {
            toast.warning("Sem conexão/timeout!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        console.log(apiError!)

        if (apiError!.response !== undefined) {
            const status = apiError!.response.status

            if (status === 404 || status === 400) {
                const data:any = apiError!.response.data
                const messageData:string | string[] = data.message
                let msg:string = ""
                
                if (typeof(messageData) === "string") {
                    msg = messageData
                }

                if (typeof(messageData) === "object") {
                    msg = messageData[0]
                }

                toast.warning(msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }

            if (status === 500) {
                toast.error("Erro na API, tente novamente mais tarde ou verifique com o suporte!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        const response:ReqResponse = {
            data: null,
            status: 0
        }
    
        return response
    }

    const response:ReqResponse = {
        data: apiResponse!.data,
        status: apiResponse!.status
    }

    return response

}

export async function reqPostDownloadFile(url:LinkEndpoint, data:any, filename:string): Promise<ReqResponse> {

    let statusCode = await new Promise(function(resolve, reject) {
        let request = new XMLHttpRequest();
        let token = getCookie('token')
        let link = url.endpoint
    
        request.responseType = "blob";
        request.open("POST", link, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", `Bearer ${token}`)
        request.send(JSON.stringify(data))
        
        request.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const imageURL = window.URL.createObjectURL(this.response)
        
                const anchor = document.createElement("a")
                anchor.href = imageURL
                anchor.download = filename
                document.body.appendChild(anchor)
                anchor.click()
    
                resolve(200)
    
            } else if (this.status === 400) {
    
                resolve(400)
    
            }
    
            resolve(this.status)
    
        }
    })

    return {
        "status": Number(statusCode),
        "data": null
    }

}
