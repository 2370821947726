import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiEndpoints, reqGetNew, reqPatch } from "../../config/apiClient"
import removeAllErrorMsg from "../../components/funcRemoveAllErros"
import funcSleep from "../../components/funcSleep"
import LoadingModal from "../../components/loadingModal"
import { defineValueInInput } from "../../components/funcForInput"
import { iNfConfigDados } from "./dto/nf_config"

function NfConfigFormPage() {
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        document.title = "NF Config. | OB PORTUS"

        const getDados = async () => {
            setLoading(true)

            const api = await reqGetNew(ApiEndpoints.nfConfigConsultar)

            if (api.status === 200) {
                const config:iNfConfigDados = api.data
                console.log(config)

                defineValueInInput('cancelar_layout_req_ah', config.ah.cancelar_layout_req)
                defineValueInInput('cancelar_layout_res_ah', config.ah.cancelar_layout_res)
                defineValueInInput('cancelar_link_ah', config.ah.cancelar_link)

                defineValueInInput('consultar_layout_req_ah', config.ah.consultar_layout_req)
                defineValueInInput('consultar_layout_res_ah', config.ah.consultar_layout_res)
                defineValueInInput('consultar_link_ah', config.ah.consultar_link)

                defineValueInInput('enviar_layout_req_ah', config.ah.enviar_layout_req)
                defineValueInInput('enviar_layout_res_ah', config.ah.enviar_layout_res)
                defineValueInInput('enviar_link_ah', config.ah.enviar_link)

                defineValueInInput('imprimir_link_ah', config.ah.enviar_link)

                defineValueInInput('cancelar_layout_req_prod', config.prod.cancelar_layout_req)
                defineValueInInput('cancelar_layout_res_prod', config.prod.cancelar_layout_res)
                defineValueInInput('cancelar_link_prod', config.prod.cancelar_link)

                defineValueInInput('consultar_layout_req_prod', config.prod.consultar_layout_req)
                defineValueInInput('consultar_layout_res_prod', config.prod.consultar_layout_res)
                defineValueInInput('consultar_link_prod', config.prod.consultar_link)

                defineValueInInput('enviar_layout_req_prod', config.prod.enviar_layout_req)
                defineValueInInput('enviar_layout_res_prod', config.prod.enviar_layout_res)
                defineValueInInput('enviar_link_prod', config.prod.enviar_link)

                defineValueInInput('imprimir_link_prod', config.prod.enviar_link)
            }

            setLoading(false)
        }

        getDados()
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados : iNfConfigDados = {
            ah: {
                ambiente: "ah",
                enviar_link: form.enviar_link_ah.value,
                enviar_layout_req: form.enviar_layout_req_ah.value,
                enviar_layout_res: form.enviar_layout_res_ah.value,
                consultar_link: form.consultar_link_ah.value,
                consultar_layout_req: form.consultar_layout_req_ah.value,
                consultar_layout_res: form.consultar_layout_res_ah.value,
                cancelar_link: form.cancelar_link_ah.value,
                cancelar_layout_req: form.cancelar_layout_req_ah.value,
                cancelar_layout_res: form.cancelar_layout_res_ah.value,
                imprimir_link: form.imprimir_link_ah.value,
            },
            prod: {
                ambiente: "prod",
                enviar_link: form.enviar_link_prod.value,
                enviar_layout_req: form.enviar_layout_req_prod.value,
                enviar_layout_res: form.enviar_layout_res_prod.value,
                consultar_link: form.consultar_link_prod.value,
                consultar_layout_req: form.consultar_layout_req_prod.value,
                consultar_layout_res: form.consultar_layout_res_prod.value,
                cancelar_link: form.cancelar_link_prod.value,
                cancelar_layout_req: form.cancelar_layout_req_prod.value,
                cancelar_layout_res: form.cancelar_layout_res_prod.value,
                imprimir_link: form.imprimir_link_prod.value,
            },
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        editar(dados)
    
    }

    async function editar(dados: any) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.nfConfigEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Config editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)
        }

        setLoading(false)
    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Config</h1>
            </div>
            <br></br>
            <Form id='formNfConfig' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <h2><b>Homologação (AH)</b></h2>
                    </Col>
                    <Col className="col-6">
                        <h2><b>Produção (PROD)</b></h2>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>cancelar_layout_req_ah:</Form.Label>
                            <Form.Control name='cancelar_layout_req_ah' type="text" id='cancelar_layout_req_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_layout_req_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>cancelar_layout_res_ah:</Form.Label>
                            <Form.Control name='cancelar_layout_res_ah' type="text" id='cancelar_layout_res_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_layout_res_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>cancelar_layout_req_prod:</Form.Label>
                            <Form.Control name='cancelar_layout_req_prod' type="text" id='cancelar_layout_req_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_layout_req_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>cancelar_layout_res_prod:</Form.Label>
                            <Form.Control name='cancelar_layout_res_prod' type="text" id='cancelar_layout_res_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_layout_res_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>cancelar_link_ah:</Form.Label>
                            <Form.Control name='cancelar_link_ah' type="text" id='cancelar_link_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_link_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>cancelar_link_prod:</Form.Label>
                            <Form.Control name='cancelar_link_prod' type="text" id='cancelar_link_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o cancelar_link_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>consultar_layout_req_ah:</Form.Label>
                            <Form.Control name='consultar_layout_req_ah' type="text" id='consultar_layout_req_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_layout_req_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>consultar_layout_res_ah:</Form.Label>
                            <Form.Control name='consultar_layout_res_ah' type="text" id='consultar_layout_res_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_layout_res_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>consultar_layout_req_prod:</Form.Label>
                            <Form.Control name='consultar_layout_req_prod' type="text" id='consultar_layout_req_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_layout_req_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>consultar_layout_res_prod:</Form.Label>
                            <Form.Control name='consultar_layout_res_prod' type="text" id='consultar_layout_res_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_layout_res_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>consultar_link_ah:</Form.Label>
                            <Form.Control name='consultar_link_ah' type="text" id='consultar_link_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_link_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>consultar_link_prod:</Form.Label>
                            <Form.Control name='consultar_link_prod' type="text" id='consultar_link_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o consultar_link_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>enviar_layout_req_ah:</Form.Label>
                            <Form.Control name='enviar_layout_req_ah' type="text" id='enviar_layout_req_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_layout_req_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>enviar_layout_res_ah:</Form.Label>
                            <Form.Control name='enviar_layout_res_ah' type="text" id='enviar_layout_res_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_layout_res_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>enviar_layout_req_prod:</Form.Label>
                            <Form.Control name='enviar_layout_req_prod' type="text" id='enviar_layout_req_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_layout_req_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>enviar_layout_res_prod:</Form.Label>
                            <Form.Control name='enviar_layout_res_prod' type="text" id='enviar_layout_res_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_layout_res_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>enviar_link_ah:</Form.Label>
                            <Form.Control name='enviar_link_ah' type="text" id='enviar_link_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_link_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>enviar_link_prod:</Form.Label>
                            <Form.Control name='enviar_link_prod' type="text" id='enviar_link_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o enviar_link_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>imprimir_link_ah:</Form.Label>
                            <Form.Control name='imprimir_link_ah' type="text" id='imprimir_link_ah' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o imprimir_link_ah!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>imprimir_link_prod:</Form.Label>
                            <Form.Control name='imprimir_link_prod' type="text" id='imprimir_link_prod' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o imprimir_link_prod!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Salvar Config.'}</Button>
            </Form>
        </div>
    )
}

export default NfConfigFormPage